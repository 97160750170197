import React from "react";
import LeftRightDiv from "./LeftRightDiv";
import { BookOpen, Person, Pin, QuestionFill } from "akar-icons";
import { returnOneName } from "../../../constants/utils";

function PrimaryFarmHolding({ data }) {
  let dataPassed = data !== null ? data[0] : null;

  return (
    <div className="container-fluid">
      <div className="row">
      {dataPassed !== null && (
        <>
        <div className="col-sm-6 p-0">
              <LeftRightDiv>
                {/* <p><BookOpen/> Total crop land size <strong>{dataPassed["group_Section_C-acreage-Acreage_Crops"]} acres</strong></p> */}
                <span className="muted">
                  <Person color="blue" />
                   {
                       returnOneName(dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Name"
                        ]
                      )}, {dataPassed.county_n} county</span>
              </LeftRightDiv>
            </div>

        {dataPassed["group_Section_B-gps-Accuracy"] > 25 &&
        
        <>
          <div className="alert alert-danger">
          <QuestionFill color="red"  />  GPS accuracy is more than 25m
          </div>
        </>
        }
          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Details on Main Farm
            </div>

            <div class="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <LeftRightDiv text="Descriptive Name of Farm">
                    {dataPassed["group_Section_C-Desc_Main_Farm"]}
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Status of land">
                    {Array.isArray(dataPassed["group_Section_C-land_status"]) ? <>
                    {dataPassed["group_Section_C-land_status"].map((d)=>{
                      return(
                        <div className="badge bg-primary mx-1">
                          {d["value"]}
                        </div>
                      )
                    })}
                    </>:"---"}
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size of the farm">
                    {dataPassed["group_Section_C-acreage-Acreage_Total"]} acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size owned">
                    {dataPassed["group_Section_C-acreage-Acreage_owned"]} acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size leased in">
                    {dataPassed["group_Section_C-acreage-Acreage_leased_in"]}{" "}
                    acres
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size for Crops">
                    {dataPassed["group_Section_C-acreage-Acreage_Crops"]} acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size for Livestock">
                    {dataPassed["group_Section_C-acreage-Acreage_Livestock"]}{" "}
                    acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size leased out">
                    {dataPassed["group_Section_C-acreage-Acreage_Leased_out"]}{" "}
                    acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Total Land Size idle">
                    {dataPassed["group_Section_C-acreage-Acreage_Idle"]} acres
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="GPS Latitude  (From -5.00 to 5.00)">
                    {dataPassed["group_Section_B-gps-Latitude"]}
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="GPS Longitude  (From 33.70 to 42.00)">
                    {dataPassed["group_Section_B-gps-Longitude"]}
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Altitude">
                    {dataPassed["group_Section_B-gps-Altitude"]}
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="GPS Accuracy">
                    <span
                    style={{color: dataPassed["group_Section_B-gps-Accuracy"] > 25 ? "red" : "green"}}
                    >
                    {dataPassed["group_Section_B-gps-Accuracy"]}

                    </span>
                  </LeftRightDiv>
                </div>

              

              </div>

              <div className="row">
              <div className="col-sm-6">
                  <LeftRightDiv text="Map">
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${dataPassed["group_Section_B-gps-Latitude"]},${dataPassed["group_Section_B-gps-Longitude"]}`}
                    >
                      <button className="btn btn-primary btn-sm">
                      <Pin/>   View farm on Google Maps
                      </button>
                    </a>
                  </LeftRightDiv>
                </div>
                <hr></hr>
              </div>

              <div className="row">
              <div className="col-sm-6">
                  <LeftRightDiv text="Do you have land elsewhere?">
                    {dataPassed["group_Section_C-Land_elsewhere"] === 1? "Yes":"No"}
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Main Farm Enterprises
            </div>
            <div class="card-body">
              <div>
                <div className="col-sm-6">
                  <LeftRightDiv
                    text="
What enterprises were on this farm in the last 12 months? (tick where applicable only) *"
                  >
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked1"
                        checked={
                          dataPassed["group_Section_D-crops_for_subsistence"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked1">
                        Growing crops for subsistence
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked2"
                        checked={
                          dataPassed["group_Section_D-crops_for_sale"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked2">
                        Growing crops for sale
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked3"
                        checked={
                          dataPassed["group_Section_D-livestock_for_subsistence"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked3">
                        Rearing livestock for subsistence
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked4"
                        checked={
                          dataPassed["group_Section_D-livestock_for_sale"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked4">
                        Rearing livestock for sale
                      </label>
                    </div>

                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked5"
                        checked={
                          dataPassed["group_Section_D-aquaculture_for_subsistence"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked5">
                        Aquaculture  for subsistence
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked6"
                        checked={
                          dataPassed["group_Section_D-aquaculture_for_sale"] === 1
                            ? true
                            : false
                        }
                      />
                      <label class="form-check-label" for="flexCheckChecked6">
                        Aquaculture  for sale
                      </label>
                    </div>
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      </div>
    
    </div>
  );
}

export default PrimaryFarmHolding;

{
  /* <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
  <label class="form-check-label" for="flexCheckChecked">
    Checked checkbox
  </label>
</div> */
}
