import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import L from "leaflet";
import "leaflet/dist/leaflet.css";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

const customIcon = new L.Icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
  shadowSize: [41, 41],
});
const MapComponent = ({ data }) => {
  const coordinates = [
    { lat: 51.505, lon: -0.09 },
    { lat: 51.51, lon: -0.1 },
    { lat: 51.49, lon: -0.08 },
    { lat: 51.49, lon: -0.12 },
  ];

  let coord2 = [
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 29347320.0,
      lat: -1.2007914,
      lng: 37.3721298,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 4415848.0,
      lat: -1.1909076,
      lng: 37.4678468,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 32756697.0,
      lat: -1.5573258,
      lng: 37.2998989,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 24750906.0,
      lat: -1.1642971,
      lng: 37.4789567,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 21325125.0,
      lat: -1.3760332,
      lng: 37.4827936,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 3549262.0,
      lat: -1.2011673,
      lng: 37.3708315,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 10289552.0,
      lat: -1.4389406,
      lng: 37.6864685,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 32766574.0,
      lat: -1.5610055,
      lng: 37.2521221,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 6094047.0,
      lat: -1.5390959,
      lng: 37.2904395,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 1327338.0,
      lat: -1.5392537,
      lng: 37.2908165,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 21174216.0,
      lat: -1.1627356,
      lng: 37.4789302,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 10611661.0,
      lat: -1.201885,
      lng: 37.37065,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 36445858.0,
      lat: -1.1904603,
      lng: 37.4674798,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 22793141.0,
      lat: -1.2103983,
      lng: 37.645795,
      id: "Sample famer x",
    },
    {
      "group_Section_B-group_farmer_details-Farmer_IDNumebr": 1474277.0,
      lat: -1.1624656,
      lng: 37.4778238,
      id: "Sample famer x",
    },
  ];
  const kenyaCoordinates = [1.2921, 36.8219];

  coord2 = data === undefined ? coord2 : data;

  // Create a marker cluster group

  const markerComponents = coord2.map((marker, index) => (
    <Marker position={[marker.lat, marker.lng]} key={index}>
      <Popup>
        <p className="mb-1">{marker["id"]}</p>
        <br></br>
        <span className="text-muted">
          Land size {marker["land_size"]} acres
        </span>
        <br></br>
        {marker["KEY"] !== undefined && (
          <a
            style={{ color: "white" }}
            target="_blank"
            href={`/data/view-farmer/${marker["KEY"]}`}
            className="btn btn-success"
          >
            View farmer
          </a>
        )}
      </Popup>
    </Marker>
  ));

  return (
    <>
      <MapContainer
        style={{ height: "500px", width: "100%" }}
        scrollWheelZoom={false}
        center={kenyaCoordinates}
        zoom={6}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors. Implemented by KALRO'
        />
        <MarkerClusterGroup>{markerComponents}</MarkerClusterGroup>
        {/* {coord2.map((coord, index) => (
          <Marker
            icon={customIcon}
            key={index}
            position={[
              coord["lat"],
              coord["lng"],
            ]}
          >
            <Popup>
              <p className="mb-1">
              {coord["group_Section_B-group_farmer_details-Farmer_IDNumebr"]}
              </p>
              <br></br>

              <button className="btn btn-success">
                View farmer
              </button>
            </Popup>
          </Marker>
        ))} */}
      </MapContainer>
    </>
  );
};

export default MapComponent;
