import React from "react";
import LeftRightDiv from "./LeftRightDiv";

function Aquaculture({ data, farmer_data }) {
  let aqua_agriculture =
    data !== null
      ? data.hasOwnProperty("aqucaulture_data")
        ? data["aqucaulture_data"]
        : null
      : null;

  let dataPassed = farmer_data !== null ? farmer_data[0] : null;
  return (
    <div className="container">
      
      {aqua_agriculture !== null && (
        <>
          {aqua_agriculture.map((d) => {
            return (
              <>
                <div class="card p-0   mb-3">
                  <div class="card-header m-0 bg-success text-white">
                    {d.aquaprod_label}
                  </div>
                  <div class="card-body">
                    <div className="row">{/*  */}
                    <div className="col-sm-6">
                        <LeftRightDiv text="Number of active units">
                          {d["grp_Aqua_Prod_systems-num_active"]} 
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-6">
                        <LeftRightDiv text="Active volumes">
                          {d["grp_Aqua_Prod_systems-area_volume_active"]} 
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-6">
                        <LeftRightDiv text="Number of active units">
                          {d["grp_Aqua_Prod_systems-num_inactive"]} 
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-6">
                        <LeftRightDiv text="Inactive volumes">
                          {d["grp_Aqua_Prod_systems-area_volume_inactive"]} 
                        </LeftRightDiv>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      )}
    </div>
  );
}

export default Aquaculture;
