import React from "react";
import LeftRightDiv from "../LeftRightDiv";
import { CheckBoxFill } from "akar-icons";

function Cattle({ data }) {
  return (
    <div>
      <div className="row p-0">
        {data.map((d) => {
          return (
            <div className="col-sm-12">
              <div className="col-sm-12">
                <div class="card p-0  mb-3">
                  <div class="card-header m-0 bg-success text-white">
                    {d.cattle_label}
                  </div>
                  <div class="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        
                        <LeftRightDiv column={true} text="Type">
                          {d["type_cattle"]}
                        </LeftRightDiv>
                      </div>
                     <div className="col-sm-3">
                     <LeftRightDiv column={true} text="Numbers">
                         {/* <p>Dairy dry - {d["dairy_age_group-dairy_dry"]}</p>
                         <p>Dairy milked - {d["dairy_age_group-dairy_milked"]}</p>
                         <p>Heifer- {d["dairy_age_group-heifer"]}</p>
                         <p>Mature males- {d["dairy_age_group-mature_males"]}</p>
                         <p>Immature males- {d["dairy_age_group-immature_males"]}</p>
                         <p>Dairy calves- {d["dairy_age_group-calves"]}</p> */}
                         <p>Total Cattle- {d["group_cattle_details-cattle_numbers"]}</p>

                         <p>Beef group calves- {d["beef_age_group-calves"]}</p>
                         <p>Heifer beef- {d["beef_age_group-heifer_beef"]}</p>
                         <p>Beef mature females- {d["beef_age_group-mature_females"]}</p>
                         <p>Beef mature females beef- {d["beef_age_group-mature_females_beef"]}</p>
                         <p>Beef young males- {d["beef_age_group-young_males"]}</p>
                         <p>Beef mature males- {d["beef_age_group-mature_males_beef"]}</p>
                         <p>Beef Oxen- {d["beef_age_group-oxen"]}</p>


                        </LeftRightDiv>
                        </div>

                        <div className="col-sm-3">
                     <LeftRightDiv column={true} text="Use fertilizer">
                     {d["group_cattle_details-cattle_fertilizer"] ===1 ? "Yes":"No"}

                        </LeftRightDiv>
                        </div>
                        <div className="col-sm-3">
                     <LeftRightDiv column={true} text="Use certified seeds">
                     {d["group_cattle_details-cattle_certifiedseeds"] ===1 ? "Yes":"No"}

                        </LeftRightDiv>
                        </div>
                      <div className="col-sm-6">
                        <LeftRightDiv column={true} text="Feeds">
                          {Array.isArray(
                            d["group_cattle_details-cattle_feeds"]
                          ) && (
                            <>
                              {d["group_cattle_details-cattle_feeds"].map(
                                (feed) => {
                                  return <p> <CheckBoxFill color="green"></CheckBoxFill> {feed.value}</p>;
                                }
                              )}
                            </>
                          )}
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-6">
                        <LeftRightDiv column={true} text="Production System">
                          {Array.isArray(
                            d["group_cattle_details-cattle_production"]
                          ) && (
                            <>
                              {d["group_cattle_details-cattle_production"].map(
                                (feed) => {
                                  return <p><CheckBoxFill color="green"></CheckBoxFill> {feed.value}</p>;
                                }
                              )}
                            </>
                          )}
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-6">
                        <LeftRightDiv column={true} text="Water source">
                          {Array.isArray(
                            d["group_cattle_details-cattle_watersource"]
                          ) && (
                            <>
                              {d["group_cattle_details-cattle_watersource"].map(
                                (feed) => {
                                  return <p><CheckBoxFill color="green"></CheckBoxFill> {feed.value}</p>;
                                }
                              )}
                            </>
                          )}
                        </LeftRightDiv>
                      </div>

                      <div className="col-sm-3">
                     <LeftRightDiv column={true} text="Water source distances">
                     {d["group_cattle_details-cattle_watersourcedistance"]}

                        </LeftRightDiv>
                        </div>
                      {/* group_cattle_details-cattle_watersourcedistance */}
                      {/* group_cattle_details-cattle_watersource */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Cattle;
