import React from "react";
import LeftRightDiv from "../FarmerProfiles/LeftRightDiv";
import { Pie } from "react-chartjs-2";

function GeneralStats({
  count,
  total_sum_acreage,
  total_crops_acr_sum,
  male_number,
  female_number,
  other_gender_number,
  none_gender_number,
  data2,
  data,
  unique_sub_counties,
  subcounty_data,
}) {
  return (
    <div>
      <div className="col-sm-12">
        <div>
          <h5>General</h5>
          <hr></hr>

          <div className="row">
            <div className="col-sm-12  col-md-6 col-xs-12">
              <div className="border bg-light rounded-1 p-3">
                <div className="row">
                  <div className="col-sm-5 col-md-5">
                    <LeftRightDiv column text="Total farmers">
                      <h5>{count.toLocaleString()}</h5>
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-7 col-md-7">
                    <LeftRightDiv column text="Total land size (acres)">
                      <h5>{total_sum_acreage.toLocaleString()}</h5>
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6 col-md-12">
                    <LeftRightDiv column text="Total crop acreage (acres)">
                      <h5>{total_crops_acr_sum.toLocaleString()}</h5>
                    </LeftRightDiv>
                  </div>
                </div>
                <h5>Farmers by gender</h5>
                <hr></hr>

                <div className="row">
                  <div className="col-sm-6 col-md-6">
                    <LeftRightDiv column text="Male">
                      {male_number.toLocaleString()}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <LeftRightDiv column text="Female">
                      {female_number.toLocaleString()}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6 col-md-6">
                    <LeftRightDiv column text="Others">
                      {other_gender_number.toLocaleString()}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6 col-md-6">
                    <LeftRightDiv column text="Data without gender">
                      {none_gender_number.toLocaleString()}
                    </LeftRightDiv>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xs-12">
              <div className="border bg-light rounded-1 p-3">
                <div className="row">
                  <div style={{ height: 500 }}>
                    <Pie data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralStats;
