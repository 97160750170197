// import CompanyEditProfile from './components/pages/company/CompanyProfile/CompanyEditProfile';
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { Provider } from "react-redux";
import rootStore from "./app-redux/store";
import "./css/argon-dashboard-tailwind.css"
function App() {
  return (
    <Provider store={rootStore}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
     
    </Provider>
  );
}

export default App;
