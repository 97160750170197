import React from "react";
import Cattle from "./Cattle";
import Chicken from "./Chicken";
import Goat from "./Goat";
import Pig from "./Pig";
import Sheep from "./Sheep";
import Camel from "./Camel";
import Rabbit from "./Rabbit";
import Sericulture from "./Sericulture";
import Bee_L from "./Bee_L";
import Donkey from "./Donkey";

function Livestock({ data, farmer_data }) {
  let cattle_data =
    data !== null
      ? data.hasOwnProperty("cattle_data")
        ? data["cattle_data"]
        : null
      : null;

  let poultry_data =
    data !== null
      ? data.hasOwnProperty("poultry_data")
        ? data["poultry_data"]
        : null
      : null;

  let goat_data =
    data !== null
      ? data.hasOwnProperty("goat_data")
        ? data["goat_data"]
        : null
      : null;

  let pig_data =
    data !== null
      ? data.hasOwnProperty("pig_data")
        ? data["pig_data"]
        : null
      : null;

  let sheep_data =
    data !== null
      ? data.hasOwnProperty("sheep_data")
        ? data["sheep_data"]
        : null
      : null;

  let camel_data =
    data !== null
      ? data.hasOwnProperty("camel_data")
        ? data["camel_data"]
        : null
      : null;
  let rabbit_data =
    data !== null
      ? data.hasOwnProperty("rabbit_data")
        ? data["rabbit_data"]
        : null
      : null;
  let sericulture_data =
    data !== null
      ? data.hasOwnProperty("sericulture_data")
        ? data["sericulture_data"]
        : null
      : null;

  let bee_data =
    data !== null
      ? data.hasOwnProperty("bee_data")
        ? data["bee_data"]
        : null
      : null;
let equine_data =
data !== null
  ? data.hasOwnProperty("equine_data")
    ? data["equine_data"]
    : null
  : null;

  let dataPassed = farmer_data !== null ? farmer_data[0] : null;
  return (
    <div className="container-fluid">
      {cattle_data !== null && <Cattle data={cattle_data} />}
      {poultry_data !== null && <Chicken data={poultry_data} />}
      {goat_data !== null && <Goat data={goat_data} />}
      {pig_data !== null && <Pig data={pig_data} />}
      {camel_data !== null && <Camel data={camel_data} />}
      {sheep_data !== null && <Sheep data={sheep_data} />}
      {rabbit_data !== null && <Rabbit data={rabbit_data} />}
      {sericulture_data !== null && <Sericulture data={sericulture_data} />}
      {bee_data !== null && <Bee_L data={bee_data} />}
      {equine_data !== null && <Donkey data={equine_data} />}


      {/* <p className="text-muter">
        Still working on this module, try searching for farmer with id number
        36322469
      </p> */}
    </div>
  );
}

export default Livestock;
