import React, { useEffect, useState } from "react";
import Cards from "./pagecomponents/Cards";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountyStats_Merged,
  getWardData_Merged,
  updateCountyStatsMerged,
} from "../../../app-redux/features/appData/appDataSlice";
import PageLayout from "./pagecomponents/PageLayout";
import { getOfflineData } from "../../../constants/OfflineStorage";
import WardSelect from "./pagecomponents/WardSelect";
import SubCountySelect from "./pagecomponents/SubCountySelect";

function Dashboard() {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  let { loginUserState, county_wards } = appData;
  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;
  const [dataSelected, SetDataSelected] = useState(null);

  const { county_stats_merged, ward_data_merged } = appData;
  const { data: county_stats_merged_data, loadin_county_stats_merged } =
    county_stats_merged;
  const { data: ward_data_merged_data } = ward_data_merged;

  const [SCountySelected, SetSCountySelected] = useState(null);
  const [wardSelected, SetWardSelected] = useState(null);
  const handleWardSelect = (value) => {
    SetWardSelected(value);
    triggerQueryData(JSON.stringify({
      county:user_county,
      ward:value
    }))
  };

  const handleSCountySelect = (value) => {
    SetSCountySelected(value);
    SetWardSelected(null);
  };

  useEffect(() => {
    // if (county_stats_merged_data === null) {
    //   getOfflineData("@CountyStats_Merged").then((res1) => {
    //     dispatch(updateCountyStatsMerged(res1));
    //     // updateToken(res)
    //     if (!res1) {
    //       dispatch(getCountyStats_Merged({ county: user_county }));
    //     }
    //   });
    // }
  }, [county_stats_merged_data]);

  // console.log(JSON.stringify(county_stats_merged_data))


  const triggerQueryData = (dataPassed) => {
    let data = JSON.parse(dataPassed);
    if (dataSelected !== null) {
      if (data.ward !== dataSelected.ward) {
        SetDataSelected(data);
        // dispatch function
        dispatch(getWardData_Merged({ county: data.county, ward: data.ward }));
      }
    } else {
      SetDataSelected(data);
      // dispatch function
      dispatch(getWardData_Merged({ county: data.county, ward: data.ward }));
    }
  };


  return (
    <div>
      <PageLayout
        county_name={
          loginUserState.data !== null ? loginUserState.data.county_string : "-"
        }
      >
        {loadin_county_stats_merged && (
          <>
            <div
              className="mt-8 w-full px-6 py-6 mx-auto"
              style={{ marginTop: 10 }}
            >
              <p className="text-black">
                Please wait. Loading county stats and data. This usually happens
                once
              </p>
            </div>
          </>
        )}


        {county_stats_merged_data !== null && (
          <>
            <div className="flex bg-white w-[50%] rounded-lg">
              <div className="p-2 w-[50%]">
                <SubCountySelect
                triggerQueryData={triggerQueryData}
                  showLabels={false}
                  ScSelected={SCountySelected}
                  showData={true}
                  countySelected={user_county}
                  handleSelect={handleSCountySelect}
                  data={[]}
                ></SubCountySelect>
              </div>
              
              <div className="p-2 w-[50%]">
                <WardSelect
                triggerQueryData={triggerQueryData}
                  showLabels={false}
                  showData={SCountySelected !== null}
                  wardSelected={wardSelected}
                  countySelected={user_county}
                  handleSelect={handleWardSelect}
                  data={[]}
                  subcountySelected={SCountySelected}
                ></WardSelect>
              </div>
            </div>
            <Cards
            subcountySelected={SCountySelected}
              wardSelected={wardSelected}
              SCountySelected={SCountySelected}
              ISsubcountySelected={SCountySelected !== null ? true : false}
              ISwardSelected={wardSelected !== null ? true : false}
              isCropsPage={true}
              isLivestock={true}
              isDashboard={true}
            />
          </>
        )}
      </PageLayout>
    </div>
  );
}

export default Dashboard;
