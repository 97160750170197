import React from "react";
import LeftRightDiv from "./LeftRightDiv";
import { Block, CheckBoxFill, CircleCheck } from "akar-icons";

function Financial({ farmer_data }) {
  let dataPassed = farmer_data !== null ? farmer_data[0] : null;

  return (
    <div className="container-fluid">
      {dataPassed !== null && (
        <>
          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Household Income from farming
            </div>
            <div class="card-body">
              <div className="row">
                {/*  */}

                <div className="col-sm-6">
                  <LeftRightDiv text="Percentage of Household Income Derived from Farming">
                    {dataPassed["group_Section_J-hh_income"]} %
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              {" "}
              Financial Services
            </div>
            <div class="card-body">
              <div className="row">
                {/*  */}

                <div className="col-sm-6">
                  <LeftRightDiv text="Do you insure your crops?">
                    {dataPassed["group_Section_J-crpInsurance"] === 1 ? (
                      <>
                        <CircleCheck color="green" /> Yes
                      </>
                    ) : (
                      <span className="text-muted">
                        <Block color="gray" /> No{" "}
                      </span>
                    )}
                  </LeftRightDiv>
                </div>

                <div className="col-sm-6">
                  <LeftRightDiv text="Do you insure your livestock?">
                    {dataPassed["group_Section_J-livInsurance"] === 1 ? (
                      <>
                        <CircleCheck color="green" /> Yes
                      </>
                    ) : (
                      <span className="text-muted">
                        <Block color="gray" /> No{" "}
                      </span>
                    )}
                  </LeftRightDiv>
                </div>

                {/* group_Section_J-buildsInsurance */}
                <div className="col-sm-6">
                  <LeftRightDiv text="Do you insure your fish?">
                    {dataPassed["group_Section_J-fishInsurance"] === 1 ? (
                      <>
                        <CircleCheck color="green" /> Yes
                      </>
                    ) : (
                      <span className="text-muted">
                        <Block color="gray" /> No{" "}
                      </span>
                    )}
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Do you insure your farm buildings and other assets?">
                    {dataPassed["group_Section_J-buildsInsurance"] === 1 ? (
                      <>
                        <CircleCheck color="green" /> Yes
                      </>
                    ) : (
                      <span className="text-muted">
                        <Block color="gray" /> No{" "}
                      </span>
                    )}
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Do you keep written farm records?">
                    {dataPassed["group_Section_J-farmRecords"] === 1 ? (
                      <>
                        <CircleCheck color="green" /> Yes
                      </>
                    ) : (
                      <span className="text-muted">
                        <Block color="gray" /> No{" "}
                      </span>
                    )}
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Agricultural Info Source
            </div>
            <div class="card-body">
              <div className="row">
                {/*  */}

                <div className="col-sm-6">
                  <LeftRightDiv text="What are your main source of information on good agricultural practices (GAP) ?">
                    {Array.isArray(dataPassed["group_Section_J-AgricInfo"]) ? (
                      <>
                        {dataPassed["group_Section_J-AgricInfo"].map((d) => {
                          return (
                            <p>
                              <CheckBoxFill color="green"></CheckBoxFill>{" "}
                              {d.value}
                            </p>
                          );
                        })}
                      </>
                    ) : (
                      "---"
                    )}
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>

          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Extension Servicese
            </div>
            <div class="card-body">
              <div className="row">
                {/*  */}

                <div className="col-sm-6">
                  <LeftRightDiv text="If you get extension services, from where?">
                    {Array.isArray(
                      dataPassed["group_Section_J-Extension_Mode"]
                    ) ? (
                      <>
                        {dataPassed["group_Section_J-Extension_Mode"].map(
                          (d) => {
                            return (
                              <p>
                                {" "}
                                <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                {d.value}
                              </p>
                            );
                          }
                        )}
                      </>
                    ) : (
                      "---"
                    )}
                  </LeftRightDiv>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Financial;
