import React from "react";
import LeftRightDiv from "./LeftRightDiv";
import { Block, CheckBoxFill, CircleCheck } from "akar-icons";

function LandWaterManagement({ farmer_data }) {
  let dataPassed = farmer_data !== null ? farmer_data[0] : null;

  return (
    <div className="container-fluid">
        {/* group_Section_I-irrigation_yesno */}
      {/* group_Crop_details-crop_watersource */}
      {/* What Sustainable Land and Environmental Management Practices do you carry out? */}
      {dataPassed !== null && (
        <>
          <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              Land and water management | irrigation
            </div>
            <div class="card-body">
              <div className="row">
                <div className="col-sm-6">
                  <LeftRightDiv 
                  text="Land and Environmental Management Practices" column={true}
                  >
                    <div className="row">
                      {Array.isArray(
                        dataPassed["group_Section_I-slm_practices"]
                      ) ? (
                        <>
                          {dataPassed["group_Section_I-slm_practices"].map(
                            (d_d) => {
                              return (
                                <div className="col-sm-6">
                                  <p>
                                    {/* {d_fert.value} */}
                                    <CheckBoxFill color="green" /> {d_d.value}
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        "---"
                      )}
                    </div>
                  </LeftRightDiv>
                </div>
                <div className="col-sm-6">
                  <LeftRightDiv text="Main source of water " column={true}>
                    <div className="row">
                      {Array.isArray(
                        dataPassed["group_Section_I-main_water_source"]
                      ) ? (
                        <>
                          {dataPassed["group_Section_I-main_water_source"].map(
                            (d_d) => {
                              return (
                                <div className="col-sm-6">
                                  <p>
                                    {/* {d_fert.value} */}
                                    <CheckBoxFill color="green" /> {d_d.value}
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        "---"
                      )}
                    </div>
                  </LeftRightDiv>
                </div>


                <div className="col-sm-4">
                    <LeftRightDiv column={true} text="Have you benefited from Govt. Subsidized fertilizer?">
                      {dataPassed[
                        "group_Section_I-fertilizer_subsidy"
                      ] === 1 ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-4">
                    <LeftRightDiv column={true} text="Do you use lime on your soil?">
                      {dataPassed[
                        "group_Section_I-lime_use"
                      ] === 1 ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-4">
                    <LeftRightDiv column={true} text="Have you done soil testing in the last 3 years?">
                      {dataPassed[
                        "group_Section_I-soil_testing"
                      ] === 1 ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>
                <hr></hr>

                <div className="col-sm-4">
                    <LeftRightDiv column={true} text="Do you undertake irrigation?">
                      {dataPassed[
                        "group_Section_I-irrigation_yesno"
                      ] === 1 ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>

                  {
                    dataPassed["group_Section_I-irrigation_yesno"] !== 2 &&
                    <>
                     <div className="col-sm-4">
                    <LeftRightDiv column={true} text="Total area under irrigation">
                      {dataPassed[
                        "group_Section_I-irrigation_area"
                      ] } acres
                    </LeftRightDiv>
                  </div>

                     <div className="col-sm-4">
                  <LeftRightDiv text="If yes, What type of irrigation do you undertake?" column={true}>
                    <div className="row">
                      {Array.isArray(
                        dataPassed["group_Section_I-irrigation_type"]
                      ) ? (
                        <>
                          {dataPassed["group_Section_I-irrigation_type"].map(
                            (d_d) => {
                              return (
                                <div className="col-sm-6">
                                  <p>
                                    {/* {d_fert.value} */}
                                    <CheckBoxFill color="green" /> {d_d.value}
                                  </p>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : (
                        "---"
                      )}
                    </div>
                  </LeftRightDiv>
                </div>
                    </>
                  }

                
                 

                 
              </div>
            </div>
          </div>

          {/* <div class="card p-0   mb-3">
            <div class="card-header m-0 bg-success text-white">
              main source of water 
            </div>
            <div class="card-body">
              <div className="row">
               
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}

export default LandWaterManagement;
