import React, { useState } from "react";
import LeftRightDiv from "./LeftRightDiv";
import { Block, Calendar, CircleCheck, Phone } from "akar-icons";
import { FormatDate, convertNairobiAfrica, returnOneName } from "../../../constants/utils";

function FarmIdentification({
  data,
  showCrop,
  showLivestock,
  showTree,
  showAquaculture,
 
}) {
  let dataPassed = data !== null ? data[0] : null;
  const [showPhone, SetShowPhone] = useState(false);

  return (
    <div>
      {dataPassed !== null && (
        <div className="container-fluid">
          <div className="row p-0">
            <div className="col-sm-8 p-0">
            {/* style={{color: data["group_Section_B-gps-Accuracy"] > 25 && "red"}} */}
              <LeftRightDiv>
            <Calendar color="blue" />  <strong>Date Of Submission</strong> : {convertNairobiAfrica(dataPassed.SubmissionDate)} | <span className="text-muted">{FormatDate(dataPassed["SubmissionDate"])}</span>
                <br>
                
                </br>
                <>Time started interview</> : {dataPassed["start_time"]}  
                 <br>
                
                </br>
                <>Time ended interview</> : {dataPassed["end_time"]}  

              </LeftRightDiv>
            </div>
          </div>
          <div className="row">
            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                Enumeration Geography
              </div>
              <div class="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <LeftRightDiv text="County">
                      {dataPassed.county_n}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Constituency">
                      {dataPassed.const_name}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Sub county">
                      {dataPassed.county_n}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Division">
                      {dataPassed.div_name}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Ward">
                      {dataPassed.caw_name}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Sub location">
                      {dataPassed.sbloc_name}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text=" location">
                      {dataPassed.loc_name}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="EA code">
                      {dataPassed.ea_code}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="EA name">
                      {dataPassed.ea_name}
                    </LeftRightDiv>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                Enumerator Details
              </div>
              <div class="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <LeftRightDiv text="Enumerator Name">
                      {dataPassed["group_Section_A-enum_name"]}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="Enumerator National ID">
                    <button className="btn btn-secondary btn-sm" onClick={()=>SetShowPhone(!showPhone)}>
                        {showPhone? "hidden personal details (Consult ICT)" : "hidden personal details (Consult ICT)"}
                     </button>
                 

                      {/* {dataPassed["group_Section_A-enum_id"]} */}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Enumerator Phone number">
                    <button className="btn btn-secondary btn-sm" onClick={()=>SetShowPhone(!showPhone)}>
                        {showPhone? "hidden personal details (Consult ICT)" : "hidden personal details (Consult ICT)"}
                     </button>
                   

                      
                      {/* {dataPassed["group_Section_A-enum_phone"]} */}
                      {/* ***---*** */}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Supervisor name">
                      {dataPassed["sup_id"]}
                    </LeftRightDiv>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                Respondent question
              </div>
              <div class="card-body">
                <div className="col-sm-6">
                  <LeftRightDiv text="Is the farmer the respondent">
                    {dataPassed["group_Section_B-Respondent_Farmer_Status"] ===
                    1
                      ? "yes"
                      : "no"}
                  </LeftRightDiv>
                </div>
              </div>
            </div>

            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                Individual Farmer Personal Details
              </div>
              <div class="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <LeftRightDiv text="Farmer name">

                      {
                       returnOneName(dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Name"
                        ]
                      )}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Farmer DOB">
                      {
                        dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Birth_Year"
                        ]
                      }
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Gender">
                      {dataPassed[
                        "group_Section_B-group_farmer_details-Farmer_Sex"
                      ]}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="National ID No">
                    {
                        showPhone && <>
                        {
                        dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_IDNumebr"
                        ]
                      }
                        </>
                        
                      }
                     <button className="btn btn-secondary btn-sm" onClick={()=>SetShowPhone(!showPhone)}>
                        {showPhone? "hidden personal details (Consult ICT)" : "hidden personal details (Consult ICT)"}
                        </button>
                     
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Email">
                      {dataPassed[
                        "group_Section_B-group_farmer_details-Farmer_Email"
                      ] === null
                        ? "---"
                        : dataPassed[
                            "group_Section_B-group_farmer_details-Farmer_Email"
                          ]}
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="Postal Address">
                      {dataPassed[
                        "group_Section_B-group_farmer_details-Farmer_Postal_Address"
                      ] === null
                        ? "---"
                        : dataPassed[
                            "group_Section_B-group_farmer_details-Farmer_Postal_Address"
                          ]}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Farmer Phone number">
                     {/* <Phone/>  */}

                      {
                        showPhone && <>
                        {dataPassed["group_Section_B-group_farmer_details-Farmer_TelephoneNumebr"]}
                        </>
                        
                      }
                     <button className="btn btn-secondary btn-sm" onClick={()=>SetShowPhone(!showPhone)}>
                        {showPhone? "hidden personal details (Consult ICT)" : "hidden personal details (Consult ICT)"}
                     </button>
                      {/* ***---*** */}
                    </LeftRightDiv>
                  </div>

                 

                  <div className="col-sm-6">
                    <LeftRightDiv text="Marital status">
                      {dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Marital_status"
                        ]}
                     
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="Household size">
                      {
                        dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Household_Size"
                        ]
                      }
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="Highest Level of Formal Education">
                      {
                        dataPassed[
                          "group_Section_B-group_farmer_details-Farmer_Highest_Education_Level"
                        ]
                      }
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-6">
                    <LeftRightDiv text="Do you have formal training in agriculture?">
                      {dataPassed[
                        "group_Section_B-group_farmer_details-Formal_training"
                      ] === 1 ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                Production System
              </div>
              <div class="card-body">
                <div className="row">
                  <div className="col-sm-6">
                    <LeftRightDiv text="Crop Agriculture">
                   
                      {showCrop ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}

                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Tree Crop Agriculture">
                    
                        {showTree ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Livestock">
           
                      {showLivestock ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}

                    </LeftRightDiv>
                  </div>
                  <div className="col-sm-6">
                    <LeftRightDiv text="Aquaculture">

                       {showAquaculture ? (
                        <>
                          <CircleCheck color="green" /> Yes
                        </>
                      ) : (
                        <span className="text-muted">
                          <Block color="gray" /> No{" "}
                        </span>
                      )}
                    </LeftRightDiv>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FarmIdentification;
