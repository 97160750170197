import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import AppHomeNav from "../../orgamisms/AppHomeNav/AppHomeNav";
import { LockOff, LockOn } from "akar-icons";
import {
  getUserOffline,
  getUserTokenOffline,
  loginUser,
} from "../../../app-redux/features/appData/appDataSlice";
import { useDispatch } from "react-redux";
import { JsonToformData } from "../../../constants/utils";
import {
  StoreofflineLocalStorage,
  getOfflineData,
  removeValueFromOffline,
} from "../../../constants/OfflineStorage";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Styles from "./Styles.module.css";
import img1 from "./img1.png";
import img2 from "./img2.png";
import img3 from "./img3.png";
import img4 from "./img4.png";
import img5 from "./img5.png";

function Login() {
  const recaptchaRef = React.useRef();
  const dispatch = useDispatch();
  const [username, setUsername] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const navigate = useNavigate();
  const images = [img1, img2, img3, img4, img5];
  useEffect(() => {
    removeValueFromOffline("@userData");
    removeValueFromOffline("@isLoggedIn");
  }, []);

  useEffect(() => {
    document.title = "Login"; // Replace 'Your New Page Title' with your desired title}
  }, []);

  const onSubmitWithReCAPTCHA = async (e) => {
    e.preventDefault();
    // const token = await recaptchaRef.current.executeAsync();
    // console.log(token)

    dispatch(loginUser(JsonToformData({ username, password })))
      .unwrap()
      .then((res) => {
        // alert("success")
        setTimeout(() => {
          navigate(`/data/home`, { replace: true });
          StoreofflineLocalStorage("@userData", res);
          StoreofflineLocalStorage("@isLoggedIn", true);
         

          getOfflineData("@isLoggedIn").then((res1) => {
            dispatch(getUserTokenOffline(res1));
            // updateToken(res)
            getOfflineData("@userData").then((res2) => {
              dispatch(getUserOffline(res2));
            });
            // dispatch(getUserOffline("@userData"))
            // console.log({res})
            if (!res1) {
              // alert(0)
              dispatch(getUserTokenOffline(false));

              navigate(`/login`, { replace: true });
            }
          });
        }, 100);
      })
      .catch((err) => {
        // console.log(err);
        if (err.hasOwnProperty("message")) {
          alert(err.message);
        } else {
          if (err.hasOwnProperty("non_field_errors")) {
            alert(err.non_field_errors[0]);

            return 0;
          }
          alert("Something went wrong");
        }
      });

    // apply to form data
  };

  return (
    <div>
      <AppHomeNav></AppHomeNav>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row mt-5">
          <div className="col-sm-2"></div>
          <div className="col-sm-8 mt-5">
          
            <h3>
              {" "}
              <LockOff /> Login
            </h3>
            <hr></hr>
            <form
              className="shadow rounded-1 p-3"
              onSubmit={(e) => onSubmitWithReCAPTCHA(e)}
            >
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Username
                </label>
                <input
                  onChange={(e) => setUsername(e.target.value)}
                  type="email"
                  placeholder="@johndoe"
                  class="form-control bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputPassword1" class="form-label">
                  Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="*******"
                  type="password"
                  class="form-control bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  id="exampleInputPassword1"
                />
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6Ld_24koAAAAAI13Nd49r0J69cYqkH0AHdTZvG2c"
              />

              <button
                onClick={(e) => onSubmitWithReCAPTCHA(e)}
                // type="submit"
                class="bg-green-500 p-2 px-5 py-2 rounded-lg text-white"
              >
                Submit
              </button>
            </form>
          
            
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
      <div className={Styles.logos_div_wrapper}>
            <div className={`${Styles.logos_div} container`}>
              {images.map((image, index) => {
                return (
                  <img className={Styles.logo} src={image} key={index}></img>
                );
              })}
            </div>
</div>
    </div>
  );
}

export default Login;
