import React from "react";
import LeftRightDiv from "../FarmerProfiles/LeftRightDiv";
import { Pie } from "react-chartjs-2";

function GeneralStats2({
  count,
  total_sum_acreage,
  total_crops_acr_sum,
  male_number,
  female_number,
  other_gender_number,
  none_gender_number,
  data2,
  data,
  unique_sub_counties,
  subcounty_data,
}) {
  return (
    <div>
      <div className="col-sm-12">
        <div>
        <h5>Sub county farmers distibution</h5>
          <hr></hr>


          <div className="row">
            <div className="col-sm-12 col-md-6 col-xs-12">
              <div className="border bg-light rounded-1 p-3">
                <div className="row mt-3">
                  {Array.isArray(unique_sub_counties) && (
                    <>
                      {unique_sub_counties.map((d, index) => {
                        return (
                          <>
                            <div className="col-sm-6 col-md-6">
                              <LeftRightDiv column text={d}>
                                <h5>
                                  {subcounty_data[index].toLocaleString()}
                                </h5>
                              </LeftRightDiv>
                            </div>
                          </>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-12  col-md-6 col-xs-12">
        <div className="border bg-light rounded-1 p-3">
         
          <div className="row">
          <div style={{ height: 500 }}>

            <Pie data={data2} />
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>
      </div>

     
    </div>
  );
}

export default GeneralStats2;
