import React from "react";
import { Route, Routes } from "react-router-dom";
import FarmerProfiles from "../components/pages/FarmerProfiles/FarmerProfiles";
import ViewFarmer from "../components/pages/FarmerProfiles/ViewFarmer";
import Login from "../components/pages/Login/Login";
import ProtectedUser from "./Layouts/ProtectedUser";
import AuthProvider from "./Providers/AuthProvider";
import Stats from "../components/pages/Statistics/Stats";
import MapComponent from "../components/orgamisms/MapComponent/MapComponents";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import CropFarmers from "../components/pages/Dashboard/CropFarmers";
import LivestockFarmers from "../components/pages/Dashboard/LivestockFarmers";

function AppRoutes() {
  return (
    <>
      <>
        <>
          <AuthProvider>
            <Routes>
              {/* "reset-password/:param1/:param2", */}
              {/* <Route exact path="/data/home" element={<FarmerProfiles />} /> */}

              <Route path="/data" element={<ProtectedUser />}>
                <Route exact path="home" element={<Dashboard />} />
                <Route exact path="crops" element={<CropFarmers />} />
                <Route exact path="livestock" element={<LivestockFarmers />} />


                <Route
                  exact
                  path="view-farmer/:uuid"
                  element={<ViewFarmer />}
                />
                <Route exact path="stats" element={<Stats />} />
                <Route exact path="map" element={<MapComponent />} />

                {/* MapComponent */}
              </Route>

              <Route exact path="/login" element={<Login />} />
              <Route exact path="/" element={<Login />} />

              {/* <Route exact path="/dashboard" element={<Dashboard />} /> */}

              {/* <Route path="*" element={<NotFound></NotFound>} /> */}
            </Routes>
          </AuthProvider>
        </>
      </>
    </>
  );
}

export default AppRoutes;
