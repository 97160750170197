import React, { useEffect, useState } from "react";
import MapComponent from "../../orgamisms/MapComponent/MapComponents";
import { getDataForMap } from "../../../app-redux/features/appData/appDataSlice";

function MapPage({ data, county_wards,user_county ,dispatch}) {
  const [wardSelected, SetWardSelected] = useState(null);
  const changeWard = (val) => {
    // SetPage(parseInt(1));
    if(val === "Filter by ward"){
        return 0
    }
    setTimeout(() => {
      SetWardSelected(val);
      dispatch(getDataForMap({ county: user_county, ward: val }));

    }, 100);
  };

  useEffect(()=>{
    if(Array.isArray(county_wards.data)){
      dispatch(getDataForMap({ county: user_county, ward: county_wards.data[0] }));
      SetWardSelected(county_wards.data[0]);

    }
  },[county_wards.data])

  return (
    <div>
        <h5>
    Visualizing data for {wardSelected}
        </h5>
      {Array.isArray(county_wards.data) && (
        <select
          class="form-select form-select-sm mb-2"
          onChange={(e) => changeWard(e.target.value)}
        >
          <option key={null} value={null}>
            Filter by ward
          </option>
          {county_wards.data.map((d) => {
            return (
              <option key={d} value={d}>
                {d}
              </option>
            );
          })}
        </select>
      )}

      <MapComponent data={data} />
    </div>
  );
}

export default MapPage;
