import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCattleStats,
  getCountyStats,
  getCountyVcStats,
  getCountyVcStats2,
  getCountyWards,
  getDataForMap,
  getFarmerProfiles,
  getVCSampleFarmers,
  getVCSampleFarmers2,
  toggleShowSampleFarmers,
} from "../../../app-redux/features/appData/appDataSlice";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AppHomeNav from "../../orgamisms/AppHomeNav/AppHomeNav";
import LeftRightDiv from "../FarmerProfiles/LeftRightDiv";
import { GraphqlFill } from "akar-icons";
import { Modal } from "react-bootstrap";
import SampleFarmers from "./SampleFarmers";
import CropsTable from "./CropsTable";
import LivestockTable from "./LivestockTable";
import MapPage from "./MapPage";
import GeneralStats from "./GeneralStats";
import ValueChainDistribution from "./ValueChainDistribution";
import ValueChainDistribution2 from "./ValueChainDistribution2";
import GeneralStats2 from "./GeneralStats2";
ChartJS.register(ArcElement, Tooltip, Legend);

function Stats() {
  const chart1Ref = useRef(null);
  const chart2Ref = useRef(null);
  const dispatch = useDispatch();
  const [isCrops, setIsCrops] = useState(1);
  const appData = useSelector((state) => state.appData);

  const { isLoadingAllData2 } = appData;
  let {
    loginUserState,
    showSampleFarmersModal,
    isLoadingAllData3,
    sample_farmers_data,
    sample_farmer_vc,
    sample_farmer_vc2,
    data_for_map,
    county_wards,
  } = appData;
  // let { loginUserState, county_wards } = appData;

  let { vc_stats_county } = appData;
  let { data: vc_stats_county_data } = vc_stats_county;
  let { data: data_for_map_data } = data_for_map;

  let { vc_stats_county2 } = appData;
  let { data: vc_stats_county_data2 } = vc_stats_county2;

  let { cattle_stats } = appData;
  let { data: cattle_stats_data } = cattle_stats;

  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;

  let count = appData.stats_county.hasOwnProperty("count")
    ? appData.stats_county.count
    : 0;

  let male_number = appData.stats_county.hasOwnProperty("male_number")
    ? appData.stats_county.male_number
    : 0;
  let female_number = appData.stats_county.hasOwnProperty("female_number")
    ? appData.stats_county.female_number
    : 0;
  let other_gender_number = appData.stats_county.hasOwnProperty(
    "other_gender_number"
  )
    ? appData.stats_county.other_gender_number
    : 0;
  let none_gender_number = appData.stats_county.hasOwnProperty(
    "none_gender_number"
  )
    ? appData.stats_county.none_gender_number
    : 0;
  let county = appData.stats_county.hasOwnProperty("county")
    ? appData.stats_county.county
    : null;

  // total_crops_acr_sum
  let total_sum_acreage = appData.stats_county.hasOwnProperty(
    "total_sum_acreage"
  )
    ? appData.stats_county.total_sum_acreage
    : 0;
  let total_crops_acr_sum = appData.stats_county.hasOwnProperty(
    "total_crops_acr_sum"
  )
    ? appData.stats_county.total_crops_acr_sum
    : 0;
  let unique_sub_counties = appData.stats_county.hasOwnProperty(
    "unique_sub_counties"
  )
    ? appData.stats_county.unique_sub_counties
    : null;
  let subcounty_data = appData.stats_county.hasOwnProperty("subcounty_data")
    ? appData.stats_county.subcounty_data
    : null;
  const data = {
    labels: ["Male", "Female", "Other", "Farmers with no gemder"],
    datasets: [
      {
        label: "Number",
        data: [
          male_number,
          female_number,
          other_gender_number,
          none_gender_number,
        ],
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(255, 99, 132)",
          "purple",
          "orange",
        ],
        hoverOffset: 4,
      },
    ],
  };

  const data2 = {
    labels: unique_sub_counties,
    datasets: [
      {
        label: "Count",
        data: subcounty_data,
        backgroundColor: [
          //   'rgba(75, 192, 192, 0.2)',
          "rgba(255, 99, 132, 0.5)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 205, 86, 0.4)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.9)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.3)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(153, 102, 255)",
          "rgb(201, 203, 207)",
        ],
        borderWidth: 1,
        //   backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  const getSampleFarmers = (d, isCrops) => {
    let datapassed = JSON.parse(d);
    dispatch(toggleShowSampleFarmers({ status: true, data: d }));
    dispatch(
      isCrops === 1
        ? getVCSampleFarmers({
            county: user_county,
            token,
            page: 1,
            vc: datapassed.vc_id,
          })
        : getVCSampleFarmers2({
            county: user_county,
            token,
            page: 1,
            vc: datapassed.vc_id,
          })
    );
  };

  let buttons = [
    { title: "Crops", id: 1 },
    { title: "Livestock", id: 2 },
  ];
  useEffect(() => {
    // dispatch(getCountyStats({ page: 1, county: "Tharaka-Nithi" }))
    token !== null &&
      !isLoadingAllData2 &&
      dispatch(getCountyStats({ page: 1, county: user_county, token: token }))
        .unwrap()
        .then((res) => {
          dispatch(getCountyWards({ county: user_county, token }));

          dispatch(
            getCountyVcStats({ page: 1, county: user_county, token: token })
          );
          // dispatch(getDataForMap({ county: user_county, ward: "null" }));

          dispatch(
            getCountyVcStats2({ page: 1, county: user_county, token: token })
          );
          dispatch(getCattleStats({ county: user_county, token }));

          dispatch(
            getFarmerProfiles({
              page: 1,
              county: user_county,
              token: token,
              ward: null,
            })
          );
          // console.log(res);
        })
        .catch((err) => {});
  }, [user_county, token]);

  let app_tabs = [
    {
      id: 1,
      title: "Map Visualization",
      component: (
        <MapPage
          dispatch={dispatch}
          user_county={user_county}
          county_wards={county_wards}
          data={data_for_map_data !== null ? data_for_map_data : undefined}
        ></MapPage>
      ),
    },
    {
      id: 2,
      title: "Crops and acreages",
      component: (
        <ValueChainDistribution
          buttons={buttons}
          vc_stats_county_data={vc_stats_county_data}
          setIsCrops={setIsCrops}
          isCrops={isCrops}
          count={count}
          getSampleFarmers={getSampleFarmers}
          vc_stats_county_data2={vc_stats_county_data2}
        ></ValueChainDistribution>
      ),
    },
    {
      id: 2.1,
      title: "Livestock",
      component: (
        <ValueChainDistribution2
          buttons={buttons}
          vc_stats_county_data={vc_stats_county_data}
          setIsCrops={setIsCrops}
          isCrops={isCrops}
          count={count}
          getSampleFarmers={getSampleFarmers}
          vc_stats_county_data2={vc_stats_county_data2}
          cattle_stats_data={cattle_stats_data}
        ></ValueChainDistribution2>
      ),
    },

    {
      id: 3,
      title: "General Statistics",
      component: (
        <GeneralStats
          // data={data_for_map_data !== null ? data_for_map_data : undefined}

          count={count}
          total_sum_acreage={total_sum_acreage}
          total_crops_acr_sum={total_crops_acr_sum}
          male_number={male_number}
          female_number={female_number}
          other_gender_number={other_gender_number}
          none_gender_number={none_gender_number}
          data2={data2}
          data={data}
          unique_sub_counties={unique_sub_counties}
          subcounty_data={subcounty_data}
        ></GeneralStats>
      ),
    },

    {
      id: 4,
      title: "Sub county Statistics",
      component: (
        <GeneralStats2
          // data={data_for_map_data !== null ? data_for_map_data : undefined}

          count={count}
          total_sum_acreage={total_sum_acreage}
          total_crops_acr_sum={total_crops_acr_sum}
          male_number={male_number}
          female_number={female_number}
          other_gender_number={other_gender_number}
          none_gender_number={none_gender_number}
          data2={data2}
          data={data}
          unique_sub_counties={unique_sub_counties}
          subcounty_data={subcounty_data}
        ></GeneralStats2>
      ),
    },
  ];
  useEffect(() => {
    if (user_county !== null && user_county !== undefined) {
      document.title = "Statistics"; // Replace 'Your New Page Title' with your desired title}
    }
  }, [user_county]);
  return (
    <>
      <AppHomeNav />

      <Modal
        size="xl"
        scrollable
        backdrop="static"
        show={showSampleFarmersModal}
        onHide={() =>
          dispatch(toggleShowSampleFarmers({ status: false, data: null }))
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {sample_farmers_data !== null
              ? `${sample_farmers_data.vc} sample farmers`
              : "Sample data"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoadingAllData3 && (
            <div class="spinner-border spinner-border-md" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          )}
          <SampleFarmers
            isCrops={isCrops}
            farmer_profiles={
              isCrops === 1 ? sample_farmer_vc : sample_farmer_vc2
            }
          ></SampleFarmers>
          {/* {JSON.stringify(sample_farmer_vc)} */}
        </Modal.Body>
      </Modal>
      <div className="container-fluid" style={{ marginTop: 60 }}>
        {/* {JSON.stringify(data)} */}
        <h2>
          {" "}
          <GraphqlFill /> {county} Stats
        </h2>

        <p className="text-muted">
          {/* Showing stats as at yesterday */}
          Disclaimer : Displays stats for the previous day and is always updated
          at 12 noon on a daily.
        </p>
        <hr></hr>

        {/* {JSON.stringify(vc_stats_county)} */}
        {isLoadingAllData2 && count === 0 && (
          <>
            <div class="spinner-border spinner-border-md" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p className="text-muted">
              Please wait... Statistics may take a while to generate.
            </p>
          </>
        )}

        {data_for_map.loading && (
          <>
            <div class="spinner-border spinner-border-md" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p className="text-muted">
              Please wait... Map may take a while to generate.
            </p>
          </>
        )}

        <Tabs
          defaultActiveKey={app_tabs[0].title}
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          {app_tabs.map((tab) => {
            return (
              <Tab
                onClick={tab.id === 2 || tab.id === '2' || tab.title === 'Crops and acreages' ? ()=>setIsCrops(1) : ()=>setIsCrops(2)}

                onSelect={tab.id === 2 || tab.id === '2' || tab.title === 'Crops and acreages' ? ()=>setIsCrops(1) : ()=>setIsCrops(2)}
                eventKey={tab.title}
                title={tab.title}
                disabled={tab.disabled}
              >
                <div>{tab.component}</div>
                {/* Tab content for {tab.title} */}
              </Tab>
            );
          })}
        </Tabs>

        <div className="row mt-3"></div>
      </div>
    </>
  );
}

export default Stats;
