import React from "react";

function CropsTable({ vc_stats_county_data, count, getSampleFarmers,isCrops }) {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th>Crop</th>
            {/* <th>Percentage</th> */}
            <th>Farmers</th>
            <th>Acreage (acres)</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {Array.isArray(vc_stats_county_data) && vc_stats_county_data
            .filter((d1) => {
              return d1.numbers !== 0;
            })
            .filter((d1) => {
              return d1.vc !== "Cocoa";
            })
            .map((d) => {
              return (
                <tr>
                  <td>{d.vc}</td>
                  {/* <td>{((d.numbers / count) * 100).toFixed(3)} % </td> */}
                  <td>
                  <div class="d-flex justify-content-between">
                  <div>{d.numbers.toLocaleString()}</div>
                  <div><span className="badge bg-info">
                  {((d.numbers / count) * 100).toFixed(3)} %
                    </span></div>

                  </div>

                      </td>
                  <td style={{backgroundColor:"#dff59d",}}>{ d.total_crops_acr_sum !== undefined && parseFloat(d.total_crops_acr_sum.toFixed(3)).toLocaleString()}</td>

                  {d.hasOwnProperty("vc_id") && (
                    <td>
                      <button
                        onClick={() => getSampleFarmers(JSON.stringify(d),parseInt(isCrops))}
                        className="btn btn-success btn-sm"
                      >
                        View sample farmers
                      </button>
                    </td>

                  )}

                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default CropsTable;
