import React from "react";

function LeftRightDiv({ text, children, column=false }) {
  return (
    <div>
      <div class="container-fluid mb-2" style={{ padding: 0 }}>
        <div  class={`${!column ? "row g-2":"row py-0"}`}>
          {text !== undefined && (
            <div class={`${!column ? "col-4":"col-12 mb-0 py-0 text-bold"}`}>
              {
                column ? 
                <div class="p-0">
                <p class="fw-normal">{text}</p>
                {/* <p className="fw-bolder">{text}</p> */}
              </div>
                :
                <div class="p-2 text-end">
                <p class="fw-normal">{text}</p>
                {/* <p className="fw-bolder">{text}</p> */}
              </div>
              }
              
            </div>
          )}

          <div class={`${!column ? "col-8":"col-12 mb-4 mt-0 py-0"}`}>
            <div class="p-2 border bg-light rounded-1">{children}</div>
          </div>
        </div>
      </div>

      {/* <div class="d-flex bd-highlight">
        <div class="p-2 flex-fill bd-highlight">{text}</div>
        <div class="p-2 flex-fill bd-highlight">{children}</div>
      </div> */}
    </div>
  );
}

export default LeftRightDiv;
