import React from "react";

function LivestockTable({ vc_stats_county_data, count, getSampleFarmers,isCrops }) {
  return (
    <div className="table-responsive">
      <table className="table table-striped table-bordered table-sm">
        <thead>
          <tr>
            <th>Livestock</th>
            <th>Farmer Percentage</th>
            <th>Number of farmers</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {Array.isArray(vc_stats_county_data) &&
          vc_stats_county_data
            .filter((d1) => {
              return d1.numbers !== 0;
            })
            .filter((d1) => {
              return d1.vc !== "Crocodiles";
            })
            .filter((d1) => {
              return d1.vc !== "camel";
            })
            .map((d) => {
              return (
                <tr>
                  <td>{d.vc}</td>
                  <td>{((d.numbers / count) * 100).toFixed(2)} % </td>
                  <td>{d.numbers.toLocaleString()} </td>
                  {d.hasOwnProperty("vc_id") && (
                    <td>
                      <button
                        onClick={() => getSampleFarmers(JSON.stringify(d),parseInt(isCrops))}
                        className="btn btn-warning btn-sm"
                      >
                        View sample farmers
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default LivestockTable;
