import React, { useEffect, useState } from "react";
import PageLayout from "./pagecomponents/PageLayout";
import CropSelect from "./pagecomponents/CropSelect";
import Cards from "./pagecomponents/Cards";
import { useDispatch, useSelector } from "react-redux";
import WardSelect from "./pagecomponents/WardSelect";
import { getWardCropData_Merged } from "../../../app-redux/features/appData/appDataSlice";
import CropsDataTable from "./pagecomponents/CropsDataTable";
import SubCountySelect from "./pagecomponents/SubCountySelect";

function CropFarmers() {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  let { loginUserState, county_wards } = appData;
  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;

  const { county_stats_merged, ward_crop_data_merged } = appData;
  const { data: county_stats_merged_data } = county_stats_merged;

  const { data: crop_data, loading: loadingCropData } = ward_crop_data_merged;

  let count_farmers_ward_query_ = county_stats_merged_data
    ? county_stats_merged_data.count_farmers_ward_query_
    : "";
  const [cropSelected, SetCropSelected] = useState(null);
  const [SCountySelected, SetSCountySelected] = useState(null);
  const [wardSelected, SetWardSelected] = useState(null);

  const handleCropSelect = (value) => {
    SetCropSelected(value);
  };
  const handleWardSelect = (value) => {
    SetWardSelected(value);
    handleQueryCropData();
  };

  const handleSCountySelect = (value) => {
    SetSCountySelected(value);
    SetWardSelected(null);
  };

  useEffect(() => {
    if (cropSelected !== null && cropSelected !== "null") {
      dispatch(
        getWardCropData_Merged({
          county: user_county,
          ward: wardSelected,
          crop_name: cropSelected,
        })
      );
    }
  }, [cropSelected]);

  const handleQueryCropData = () => {
    if (wardSelected) {
      dispatch(
        getWardCropData_Merged({
          county: user_county,
          ward: wardSelected,
          crop_name: cropSelected,
        })
      );
    }
  };
  return (
    <div>
      <PageLayout
        county_name={
          loginUserState.data !== null
            ? `${loginUserState.data.county_string} Crops`
            : "-"
        }
      >
        <Cards isCropsPage />

        <div class="flex flex-wrap mt-6 -mx-3">
          <div class="w-full max-w-full px-3 mt-0 lg:w-4/12 lg:flex-none">
            <div class="border-black/12.5 shadow-xl  relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div class="p-4 pb-0 rounded-t-4"></div>
              <div class="flex-auto p-4 h-[500px] overflow-y-auto">
                <div className="mb-5">
                  <CropSelect handleSelect={handleCropSelect} />
                </div>

                <div className="mb-5">
                  <SubCountySelect
                    ScSelected={SCountySelected}
                    showData={cropSelected !== null}
                    countySelected={user_county}
                    handleSelect={handleSCountySelect}
                    data={count_farmers_ward_query_}
                  ></SubCountySelect>
                </div>

                <div className="mb-5">
                  <>
                    <WardSelect
                      showData={SCountySelected !== null}
                      wardSelected={wardSelected}
                      countySelected={user_county}
                      handleSelect={handleWardSelect}
                      data={count_farmers_ward_query_}
                      subcountySelected={SCountySelected}
                    ></WardSelect>
                  </>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full max-w-full  mt-0 lg:w-8/12 lg:flex-none">
            <CropsDataTable
              loadingData={loadingCropData}
              dataSelected={{
                county: user_county,
                ward: wardSelected,
                crop: cropSelected,
              }}
              ward_data_merged_data={crop_data}
            ></CropsDataTable>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}

export default CropFarmers;
