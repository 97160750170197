import React, { useEffect } from "react";
import CropsTable from "./CropsTable";
import LivestockTable from "./LivestockTable";
import CattleStats from "./CattleStats";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
function ValueChainDistribution2({
  buttons,
  vc_stats_county_data,
  setIsCrops,
  isCrops,
  count,
  getSampleFarmers,
  vc_stats_county_data2,
  cattle_stats_data,
}) {
  useEffect(() => {
    setIsCrops(2);
  }, []);

  const alltabs = [
    {
      id: "1",
      title: "All",
      component: (
        <>
          <LivestockTable
            isCrops={isCrops}
            vc_stats_county_data={vc_stats_county_data2}
            count={count}
            getSampleFarmers={getSampleFarmers}
          />
        </>
      ),
    },
    {
      id: "1",
      title: "Livestock Type Stats",
      component: (
        <>
          <CattleStats cattle_stats_data={cattle_stats_data} />
        </>
      ),
    },
  ];
  return (
    <div className="container-fluid">
      <div className="col-sm-12" style={{ height: 600 }}>
        {Array.isArray(vc_stats_county_data) && (
          <>
            <h5>Livestock Value chain distribution</h5>
            <hr></hr>
            <Tabs
              defaultActiveKey={alltabs[0].title}
              id="uncontrolled-tab-example"
              className="mb-3"
              fill
            >
              {alltabs.map((tab) => {
                return (
                  <Tab
                    eventKey={tab.title}
                    title={tab.title}
                    disabled={tab.disabled}
                  >
                    <div>{tab.component}</div>
                    {/* Tab content for {tab.title} */}
                  </Tab>
                );
              })}
            </Tabs>

            {/* {buttons.map((b) => {
              return (
                <button
                  onClick={() => setIsCrops(parseInt(b.id))}
                  className={`${
                    isCrops === b.id ? "btn btn-primary" : "btn btn-secondary"
                  } mx-2 my-2`}
                >
                  {b.title}
                </button>
              );
            })} */}
            {/* {typeof(isCrops)} */}
          </>
        )}
      </div>
    </div>
  );
}

export default ValueChainDistribution2;
