import React from "react";
import Styles from "./Styles.module.css";
import {
  FormatDate,
  convertNairobiAfrica,
  returnOneName,
} from "../../../constants/utils";
import { Link } from "react-router-dom";
import { Pin, QuestionFill } from "akar-icons";

function SampleFarmers({ farmer_profiles,isCrops }) {
  return (
    <div>
      <div className={`table-responsive ${Styles.table}`}>
        <table class="table table-striped table-bordered table-sm">
          <thead>
            <tr>
              <th scope="col"></th>

              <th scope="col">Submission Date</th>
              <th scope="col">UUID</th>
              <th scope="col">Farmer Name</th>
              <th scope="col">Registered By</th>
              <th scope="col">Gender</th>
              <th scope="col">County</th>
              <th scope="col">Sub county</th>
              <th scope="col">Ward</th>
              <th scope="col">EA Code</th>
              <th scope="col">EA Name</th>

              <th scope="col">Constituency</th>
              <th scope="col">Location</th>
              <th scope="col">Sub Location</th>
              <th scope="col">Accuracy Level</th>
              <th scope="col">View Location</th>
              <th scope="col">Action</th>
            </tr>
          </thead>

          <tbody>
            {farmer_profiles.data !== null && (
              <>
                {farmer_profiles.data.map((data) => {
                  return (
                    <tr>
                      <td>
                        <span className="badge bg-success">
                        {data[isCrops ===1 ? "group_Section_E-crop":"group_Section_F-livestock"].split(" ").length -1} other {isCrops ===1 ? "crops":"livestock"} 
                        </span>
                      </td>

                      <td>
                        {FormatDate(
                          convertNairobiAfrica(data["SubmissionDate"])
                        )}
                      </td>
                      <td>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/data/view-farmer/${data["KEY"]}`}
                        >
                          {data["KEY"]}
                        </Link>

                        </td>
                     
                      <td>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/data/view-farmer/${data["KEY"]}`}
                        ></Link>

                        {data[
                          "group_Section_B-group_farmer_details-Farmer_Name"
                        ] === null ? (
                          <>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/data/view-farmer/${data["KEY"]}`}
                            >
                              View farmers
                            </Link>
                          </>
                        ) : (
                          <>
                            <Link
                              style={{ textDecoration: "none" }}
                              to={`/data/view-farmer/${data["KEY"]}`}
                            >
                              {returnOneName(
                                data[
                                  "group_Section_B-group_farmer_details-Farmer_Name"
                                ]
                              )}
                            </Link>
                          </>
                        )}
                      </td>
                      <td>
                        {returnOneName(data["group_Section_A-enum_name"])}
                      </td>
                      <td>
                        {data[
                          "group_Section_B-group_farmer_details-Farmer_Sex"
                        ] === 2
                          ? "Female"
                          : data[
                              "group_Section_B-group_farmer_details-Farmer_Sex"
                            ] === 1
                          ? "Male"
                          : "Other"}
                      </td>
                      <td>{data["county_n"]}</td>
                      <td>{data["sbcnty_name"]}</td>
                      <td>{data["caw_name"]}</td>
                      <td>{data["ea_code"]}</td>
                      <td>{data["ea_name"]}</td>

                      <td>{data["const_name"]}</td>
                      <td>{data["loc_name"]}</td>
                      <td>{data["sbloc_name"]}</td>
                      <td
                        style={{
                          color:
                            data["group_Section_B-gps-Accuracy"] > 25
                              ? "red"
                              : "green",
                        }}
                      >
                        {data["group_Section_B-gps-Accuracy"] > 25 && (
                          <QuestionFill size={11} />
                        )}
                        {data["group_Section_B-gps-Accuracy"] !== null ? data["group_Section_B-gps-Accuracy"].toFixed(2) : "-"}

                      </td>
                      <td>
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps/search/?api=1&query=${data["group_Section_B-gps-Latitude"]},${data["group_Section_B-gps-Longitude"]}`}
                        >
                          <Pin />
                          Go
                        </a>
                      </td>
                      <td>
                        <Link
                          // target="_blank"
                          to={`/data/view-farmer/${data["KEY"]}`}
                        >
                          <button className="btn btn-primary btn-sm">
                            View
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </>
            )}
          </tbody>
        </table>

        <p></p>
      </div>
    </div>
  );
}

export default SampleFarmers;
