import React from "react";
import { Col, Nav, Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function CattleStats({ cattle_stats_data }) {
  let livestocks =
    cattle_stats_data !== undefined && cattle_stats_data !== null 
      ? cattle_stats_data.map((d) => d.livestock)
      : null;
  return (
    <div className="table-responsive">
      {/* {JSON.stringify(cattle_stats_data)} */}

      {/* {JSON.stringify(livestocks)} */}

      {
        livestocks !== null &&
        <Tab.Container id="left-tabs-example" defaultActiveKey={livestocks[0]}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              {livestocks.map((tab) => {
                return (
                  <>
                    <Nav.Item>
                      <Nav.Link eventKey={tab}>{tab}</Nav.Link>
                    </Nav.Item>
                  </>
                );
              })}
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
            {livestocks.map((tab) => {
          return (
            <Tab.Pane
              eventKey={tab}
              title={tab}
              // disabled={tab}
            >
              <div>
                <table className="table table-striped table-bordered table-sm">
                  <thead>
                    <tr>
                      <th>Type</th>

                      <th>{tab} number</th>
                      <th>Number of farmers</th>
                    </tr>
                  </thead>

                  <tbody>
                    {Array.isArray(cattle_stats_data) &&
                      cattle_stats_data
                        .filter((d1) => {
                          return d1.livestock === tab;
                        })
                        .map((d) => {
                          return (
                            <>
                              {d.numbers.map((dn) => {
                                return (
                                  <>
                                    <tr>
                                      {/* aa {JSON.stringify(d.numbers)} */}
                                      <td> {dn.cattle}</td>

                                      <td>
                                        {dn.total_numbers.toLocaleString()}{" "}
                                      </td>
                                      <td>
                                        {dn.numbers_famers.toLocaleString()}{" "}
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                  </tbody>
                </table>
              </div>
              {/* Tab content for {tab.title} */}
            </Tab.Pane>
          );
        })}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      }

   

     
    </div>
  );
}

export default CattleStats;
