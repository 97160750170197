import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserOffline,
  getUserTokenOffline,
  logoutUser,
  searchFarmer,
} from "../../../app-redux/features/appData/appDataSlice";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { removeValueFromOffline } from "../../../constants/OfflineStorage";
import Styles from "./Styles.module.css";

function AppHomeNav() {
  const [query, setQuery] = useState(null);
  const [search_results, setSearchResults] = useState(undefined);
  const [showSearchModal, SetshowSearchModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appData = useSelector((state) => state.appData);
  const { isLoadingAllData } = appData;
  let { loginUserState } = appData;

  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;

  const logout = () => {
    setTimeout(() => {
      removeValueFromOffline("@userData");
      removeValueFromOffline("@isLoggedIn");
      removeValueFromOffline("@CountyStats_Merged")
      navigate(`/login`, { replace: true });
      dispatch(logoutUser());
    }, 1000);
  };
  const searchFarmerFn = (e) => {
    // alert(token)
    e.preventDefault();
    SetshowSearchModal(true);
    setSearchResults(undefined);
    setMessage(null);

    setIsLoading(true);
    dispatch(searchFarmer({ query, token, simple: true, county: user_county }))
      .unwrap()
      .then((res) => {
        // console.log(res.data)
        setIsLoading(false);

        if (res.hasOwnProperty("data")) {
          let results = res.data.farmer_data;
          let my_message = res.data.message;
          // message
          setMessage(my_message);
          setSearchResults(results);
          SetshowSearchModal(true);
        } else if (res.hasOwnProperty("message")) {
          alert(res.message);
        }
      })
      .catch((err) => {
        SetshowSearchModal(false);
        setIsLoading(false);
        if (err.hasOwnProperty("message")) {
          alert(err.message);
        } else {
          alert(err);
        }
      });
  };
  // console.log(loginUserState.isLoggedIn)
  return (
    <div>
      <>
        <Modal
          scrollable
          backdrop="static"
          show={showSearchModal}
          onHide={() => SetshowSearchModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Search results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isLoading && (
              <>
                <div class="spinner-border spinner-border-md" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p className="text-muted">Searching for farmer ...</p>
              </>
            )}
            {message !== null && (
              <>
                <p className="text-muted">{message}</p>
              </>
            )}

            {search_results !== undefined && (
              <>
                {search_results
                  .filter((res1) => {
                    return res1.county_n === user_county;
                  })
                  .map((res, index) => {
                    return (
                      <div key={index}>
                        <h4>
                          {
                            res[
                              "group_Section_B-group_farmer_details-Farmer_Name"
                            ]
                          }
                        </h4>
                        <p>
                          ID number{" "}
                          {
                            res[
                              "group_Section_B-group_farmer_details-Farmer_IDNumebr"
                            ]
                          }
                        </p>
                        <p className="text-muted">{res["county_n"]}</p>

                        <Link to={`/data/view-farmer/${res["KEY"]}`}>
                          <button className="btn btn-primary btn-sm">
                            View farmer
                          </button>
                        </Link>
                        <hr></hr>
                      </div>
                    );
                  })}
              </>
            )}
          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
        </Modal>
      </>
      <nav
        class="navbar navbar-dark bg-success navbar-expand-lg fixed-top"
        // style={{ backgroundColor: "#c5e7d7" }}
      >
        <div class="container-fluid">
          <Link class="navbar-brand" to="/data/home">
            Farmer Data
            {/* {JSON.stringify(loginUserState)} */}
            {loginUserState.isLoggedIn && (
              <>
                <Link class="ml-4 text-sm" to="#">
                  {loginUserState.data.username}
                  {/* {JSON.stringify(loginUserState)} */}
                </Link>

                <>
                  <>
                    <button
                      onClick={() => logout()}
                      className="btn btn-default text-red-300"
                    >
                      Logout
                    </button>
                    {/* <li><a class="dropdown-item" href="#">Logout</a></li> */}
                  </>
                </>
              </>
            )}
          </Link>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            {/* {JSON.stringify(loginUserState)} */}

            {loginUserState.isLoggedIn && (
              <>
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/data/home"
                    >
                      Home
                    </Link>
                  </li>
                  <li class="nav-item">
                    <Link
                      class="nav-link active"
                      aria-current="page"
                      to="/data/stats"
                    >
                      County Statistics
                    </Link>
                  </li>
                  <li class="nav-item dropdown">
                    <a
                      class="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {loginUserState.data !== null &&
                        loginUserState.data.username}
                    </a>
                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <button
                        onClick={() => logout()}
                        className="btn btn-default"
                      >
                        Logout
                      </button>
                      {/* <li><a class="dropdown-item" href="#">Logout</a></li> */}
                    </ul>
                  </li>
                </ul>
                {/* <button className="btn btn-default me-5">
              Logout</button>  */}
                <div class={`${Styles.search_div}`}>
                  <form
                    onSubmit={(e) => {
                      searchFarmerFn(e);
                    }}
                    class={`d-flex`}
                  >
                    <input
                      onChange={(e) => setQuery(e.target.value)}
                      class={`form-control me-2 ${Styles.search}`}
                      type="search"
                      placeholder="Search by Farmer Nation ID, phone, name or uuid"
                      aria-label="Search"
                    />
                    <button
                      onSubmit={(e) => {
                        searchFarmerFn(e);
                      }}
                      class="btn btn-warning"
                      type="submit"
                    >
                      Search
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default AppHomeNav;
