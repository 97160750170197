import { CheckBoxFill } from "akar-icons";
import React from "react";

function Chicken({ data }) {
  return (
    <div>
      <div class="card p-0  mb-3">
        <div class="card-header m-0 bg-success text-white">Poultry</div>
        <div class="card-body">
          <div className="table-responsive">
            <table className="ttable table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Age less 3wks</th>
                  <th>Broiler Age 3-8wks</th>
                  <th>Cockrels Age 2wk_6mnths</th>
                  <th>Pullets Age above 6mnths</th>
                  <th>Cocks Age above 6mnths</th>
                  <th>Hens Age above 6mnths</th>
                  <th>Numbers</th>
                  <th>Production</th>
                  
                  <th>Feeds</th>

                  <th>Fertilizer</th>
                  <th>Certified seeds</th>
                  <th>Feed source</th>
                  <th>Water source</th>
                  <th>Water distance</th>
                </tr>
              </thead>

              <tbody>
                {data.map((d) => {
                  return (
                    <tr>
                      <td>
                        <strong>{d["poultry_label"]}</strong>
                      </td>

                      <td>{d["poultry_age_group-chicks_less_3wks"]}</td>
                      <td>{d["poultry_age_group-broilers_3_8wks"]}</td>
                      <td>{d["poultry_age_group-cockrels_2wk_6mnths"]}</td>
                      <td>{d["poultry_age_group-pullets_above_6mnths"]}</td>
                      <td>{d["poultry_age_group-cocks_above_6mnths"]}</td>
                      <td>{d["poultry_age_group-hens_above_6mnths"]}</td>
                      {/* <td>{d["poultry_age_group-hens_above_6mnths"]}</td> */}
                      <td>{d["group_poultry_details-poultry_numbers"]}</td>
                      <td>
                        {Array.isArray(
                          d["group_poultry_details-poultry_production"]
                        ) ? (
                          <>
                            {d["group_poultry_details-poultry_production"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>
                        {Array.isArray(
                          d["group_poultry_details-poultry_feeds"]
                        ) ? (
                          <>
                            {d["group_poultry_details-poultry_feeds"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      
                      <td>{d["group_poultry_details-poultry_fertilizer"] === 1 ? "Yes":"No"}</td>
                      <td>{d["group_poultry_details-poultry_certifiedseeds"] === 1 ? "Yes":"No"}</td>
                      <td>
                        {d["group_poultry_details-poultry_feedssource"]}
                      </td>

                      <td>
                        {Array.isArray(
                          d["group_poultry_details-poultry_watersource"]
                        ) ? (
                          <>
                            {d["group_poultry_details-poultry_watersource"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>{d["group_poultry_details-poultry_watersourcedistance"]}</td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chicken;
