import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { searchFarmer } from "../../../app-redux/features/appData/appDataSlice";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AppHomeNav from "../../orgamisms/AppHomeNav/AppHomeNav";
import FarmIdentification from "./FarmIdentification";
import PrimaryFarmHolding from "./PrimaryFarmHolding";
import CropAgriculture from "./CropAgriculture";
import Aquaculture from "./Aquaculture";
import FarmEquipment from "./FarmEquipment";
import LandWaterManagement from "./LandWaterManagement";
import Financial from "./Financial";
import Styles from "./Styles.module.css";
import Livestock from "./Livestock/Livestock";
import TreeCrop from "./TreeCrop";
function ViewFarmer() {
  let { uuid } = useParams();
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  const [errorMessage, setErr] = useState(null);
  let { loginUserState } = appData;

  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;

  // (appData.farmer_profiles.data
  let loadingData = appData.farmer_profiles_search.loading;
  let farmer_profiles_search_data = appData.farmer_profiles_search.data;
  let farmer_profiles_search =
    farmer_profiles_search_data !== null
      ? farmer_profiles_search_data.hasOwnProperty("farmer_data")
        ? farmer_profiles_search_data["farmer_data"]
        : null
      : null;

  const showCropsTab =
    farmer_profiles_search !== null
      ? farmer_profiles_search.length !== 0
        ? farmer_profiles_search[0]["group_Section_E-crop"] !== null
          ? false
          : true
        : true
      : true;

  const showTreeCropsTab =
    farmer_profiles_search !== null
      ? farmer_profiles_search.length !== 0
        ? farmer_profiles_search[0]["group_Section_E_1-tree_crop"] !== null
          ? false
          : true
        : true
      : true;

  const showLivestockTab =
    farmer_profiles_search !== null
      ? farmer_profiles_search.length !== 0
        ? farmer_profiles_search[0]["group_Section_F-livestock"] !== null
          ? false
          : true
        : true
      : true;

  const showAquaTab =
    farmer_profiles_search !== null
      ? farmer_profiles_search.length !== 0
        ? farmer_profiles_search[0]["group_Section_G-aquaculture_type"] !== null
          ? false
          : true
        : true
      : true;
  // group_Section_E-crop
  let app_tabs = [
    {
      id: 1,
      title: "Farmer identification",
      component: (
        <FarmIdentification
        
          showCrop={!showCropsTab}
          showAquaculture={!showAquaTab}
          showLivestock={!showLivestockTab}
          showTree={!showTreeCropsTab}
          data={farmer_profiles_search}
        ></FarmIdentification>
      ),
    },
    {
      id: 2,
      title: "Primary Farm Holding",

      component: (
        <PrimaryFarmHolding data={farmer_profiles_search}></PrimaryFarmHolding>
      ),
    },
    {
      id: 3,
      title: "Crops",
      disabled: showCropsTab,

      component: (
        <CropAgriculture
          farmer_data={farmer_profiles_search}
          data={farmer_profiles_search_data}
        ></CropAgriculture>
      ),
    },
    {
      id: 3,
      title: "Tree Crops",
      disabled: showTreeCropsTab,
      component: <TreeCrop
      farmer_data={farmer_profiles_search}
      data={farmer_profiles_search_data}
    ></TreeCrop>
    },
    {
      id: 4,
      title: "Livestock",
      disabled: showLivestockTab,
      component:(
        <Livestock
      farmer_data={farmer_profiles_search}
      data={farmer_profiles_search_data}
    ></Livestock>
      )
    },
    {
      id: 5,
      title: "Aquaculture",
      disabled: showAquaTab,
      component: (
        <Aquaculture
          farmer_data={farmer_profiles_search}
          data={farmer_profiles_search_data}
        ></Aquaculture>
      ),
    },
    {
      id: 6,
      title: "Farm technology & assets",
      disabled: false,
      component: (
        <FarmEquipment
          farmer_data={farmer_profiles_search}
          data={farmer_profiles_search_data}
        ></FarmEquipment>
      ),
    },
    {
      id: 7,
      title: "Land & water management",
      disabled: false,
      component: (
        <LandWaterManagement
          farmer_data={farmer_profiles_search}
        ></LandWaterManagement>
      ),
    },
    {
      id: 8,
      title: "Financial Services",
      disabled: false,
      component: <Financial farmer_data={farmer_profiles_search}></Financial>,
    },
  ];

  useEffect(() => {
      document.title = "View farmer"; // Replace 'Your New Page Title' with your desired title}
  }, []);

  useEffect(() => {
    dispatch(searchFarmer({ query: uuid, token,"simple":false ,"county":user_county}))
      .unwrap()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        if (err.hasOwnProperty("message")) {
          // alert(err.message);
          setErr(err.message);
        } else {
          setErr("Something went wrong!");
          // alert(err);
        }
      });
  }, []);

  return (
    <div>
      <AppHomeNav />

      {/* {JSON.stringify(farmer_profiles_search_data)} */}
      <div className="container-fluid" style={{ marginTop: 70 }}>
        <Tabs
          defaultActiveKey={app_tabs[0].title}
          id="uncontrolled-tab-example"
          className="mb-3"
          fill
        >
          {app_tabs.map((tab) => {
            return (
              <Tab
                eventKey={tab.title}
                title={tab.title}
                disabled={tab.disabled}
              >
                <div className={Styles.main_content}>{tab.component}</div>
                {/* Tab content for {tab.title} */}
              </Tab>
            );
          })}
        </Tabs>

        {errorMessage !== null && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}
        {loadingData && <h4 className="text-center">Loading data...</h4>}
      </div>
    </div>
  );
}

export default ViewFarmer;
