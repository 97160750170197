import { CheckBoxFill } from "akar-icons";
import React from "react";

function Goat({ data }) {
  return (
    <div>
      <div class="card p-0  mb-3">
        <div class="card-header m-0 bg-success text-white">Goat</div>
        <div class="card-body">
          <div className="table-responsive">
            <table className="ttable table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Age less 12mnths female</th>
                  <th>Age less 12mnths male</th>
                  <th>Age above 1 yr female</th>
                  <th>Age above 1yr male</th>
                  <th>Age group in milk</th>
                  <th>Age group dry</th>
                  <th>Numbers</th>
                  <th>Production</th>
                  <th>Feeds</th>
                  <th>Fertilizer</th>
                  <th>Certified seeds</th>
                  <th>Feed source</th>
                  <th>Water source</th>
                  <th>Water distance</th>
                </tr>
              </thead>

              <tbody>
                {data.map((d) => {
                  return (
                    <tr>
                      <td>
                        <strong>{d["goat_label"]}</strong>
                      </td>

                      <td>{d["goat_age_group-goat_less_12mnths_female"]}</td>
                      <td>{d["goat_age_group-goat_less_12mnths_male"]}</td>
                      <td>{d["goat_age_group-goat_above_1yrs_female"]}</td>
                      <td>{d["goat_age_group-goat_above_1yrs_male"]}</td>
                      <td>{d["goat_age_group-goat_in_milk"]}</td>
                      <td>{d["goat_age_group-goat_dry"]}</td>
                      <td>{d["group_goat_details-goat_numbers"]}</td>
                      <td>
                        {Array.isArray(
                          d["group_goat_details-goat_production"]
                        ) ? (
                          <>
                            {d["group_goat_details-goat_production"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>
                        {Array.isArray(
                          d["group_goat_details-goat_feeds"]
                        ) ? (
                          <>
                            {d["group_goat_details-goat_feeds"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>

                      <td>{d["group_goat_details-goat_fertilizer"] === 1 ? "Yes":"No"}</td>
                      <td>{d["group_goat_details-goat_certifiedseeds"] === 1 ? "Yes":"No"}</td>
                      <td>
                        {d["group_goat_details-goat_feedssource"]}
                      </td>

                      <td>
                        {Array.isArray(
                          d["group_goat_details-goat_watersource"]
                        ) ? (
                          <>
                            {d["group_goat_details-goat_watersource"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>{d["group_goat_details-goat_watersourcedistance"]}</td>

                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Goat;
