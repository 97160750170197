import React, { useEffect } from "react";
import CropsTable from "./CropsTable";
import LivestockTable from "./LivestockTable";

function ValueChainDistribution({
  buttons,
  vc_stats_county_data,
  setIsCrops,
  isCrops,
  count,
  getSampleFarmers,
  vc_stats_county_data2,
}) {

  useEffect(()=>{
    setIsCrops(1)
  },[])
  return (
    <div className="container-fluid">
      <div className="col-sm-12" style={{ height: 600 }}>
        {Array.isArray(vc_stats_county_data) && (
          <>
            <h5>Crops Value chain distribution</h5>
            <hr></hr>

            {/* {buttons.map((b) => {
              return (
                <button
                  onClick={() => setIsCrops(parseInt(b.id))}
                  className={`${
                    isCrops === b.id ? "btn btn-primary" : "btn btn-secondary"
                  } mx-2 my-2`}
                >
                  {b.title}
                </button>
              );
            })} */}
            {/* {typeof(isCrops)} */}

              <CropsTable
                isCrops={isCrops}
                vc_stats_county_data={vc_stats_county_data}
                count={count}
                getSampleFarmers={getSampleFarmers}
              ></CropsTable>
            
          </>
        )}
      </div>
    </div>
  );
}

export default ValueChainDistribution;
