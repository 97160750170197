import React from "react";
import LeftRightDiv from "./LeftRightDiv";
import { CheckBoxFill } from "akar-icons";

function FarmEquipment({ data, farmer_data }) {
  let farm_equipment_data =
    data !== null
      ? data.hasOwnProperty("farm_equipment_data")
        ? data["farm_equipment_data"]
        : null
      : null;

  let dataPassed = farmer_data !== null ? farmer_data[0] : null;
  return (
    <div className="container-fluid">
      <>
        {farm_equipment_data !== null && (
          <>
            <div className="row p-0">
              {farm_equipment_data.map((d) => {
                return (
                  <div className="col-sm-6">
                    <div class="card p-0   mb-3">
                      <div class="card-header m-0 bg-success text-white">
                        {d.farm_equipment_label}
                      </div>
                      <div class="card-body">
                        <div className="row">
                          {/*  */}
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Number">
                              {d["grp_quantity_status-num_tools"]}
                            </LeftRightDiv>
                          </div>

                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Status of tool">
                              {d["grp_quantity_status-status_tools"]}
                            </LeftRightDiv>
                          </div>
                          {d["grp_quantity_status-status_tools_other"] !==
                            null && (
                            <>
                              <div className="col-sm-6">
                                <LeftRightDiv column={true} text="Other tools">
                                  {d[
                                    "grp_quantity_status-status_tools_other"
                                  ] !== null
                                    ? d[
                                        "grp_quantity_status-status_tools_other"
                                      ]
                                    : "---"}
                                </LeftRightDiv>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div class="card p-0   mb-3">
              <div class="card-header m-0 bg-success text-white">
                {/* {d.farm_equipment_label} */}
                Farm equipments | Farmer Machinery | Ownership
              </div>
              <div class="card-body">
                <div className="row">
                  {/*  */}
                  <div className="col-sm-4">
                    <LeftRightDiv text="Labor source" column={true}>
                      <>
                        {dataPassed["group_Section_H-labour_source"]}
                      </>
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-4">
                    <LeftRightDiv text="Who owns most of the frequently used equipment?" column={true}>
                      <>
                        {dataPassed["group_Section_H-equipment_owner"]}
                      </>
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-4">
                    <LeftRightDiv text="Farm equipments" column={true}>
                      <div className="row">
                        {Array.isArray(
                          dataPassed["group_Section_H-farm_structures"]
                        ) ? (
                          <>
                            {dataPassed["group_Section_H-farm_structures"].map(
                              (d) => {
                                return (
                                  <>
                                    <div className="col-sm-6">
                                      <CheckBoxFill color="green" />
                                      {d.value}
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </div>
                    </LeftRightDiv>
                  </div>

                  <div className="col-sm-4">
                    <LeftRightDiv text="Farmer Machinery " column={true}>
                      <div className="row">
                        {Array.isArray(
                          dataPassed["group_Section_H-farm_equipment"]
                        ) ? (
                          <>
                            {dataPassed["group_Section_H-farm_equipment"].map(
                              (d) => {
                                return (
                                  <>
                                    <div className="col-sm-12">
                                      <CheckBoxFill color="green" />
                                      {d.value}
                                    </div>
                                  </>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </div>
                    </LeftRightDiv>
                  </div>

                  {/* {d["group_Section_H-farm_structures"]} */}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
}
// group_Section_H-farm_structures
export default FarmEquipment;
