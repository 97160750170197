import React, { useEffect, useState } from "react";
import PageLayout from "./pagecomponents/PageLayout";

import Cards from "./pagecomponents/Cards";
import { useDispatch, useSelector } from "react-redux";
import WardSelect from "./pagecomponents/WardSelect";
import {  getWardLivestockData_Merged } from "../../../app-redux/features/appData/appDataSlice";
import LivestockSelect from "./pagecomponents/LivestockSelect";
import LivestockDataTable from "./pagecomponents/LivestockDataTable";


function LivestockFarmers() {
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.appData);
  let { loginUserState, county_wards } = appData;
  let user_county =
    loginUserState.data !== null ? loginUserState.data.county_string : null;
  let token = loginUserState.data !== null ? loginUserState.data.token : null;

  const { county_stats_merged, ward_livestock_data_merged } = appData;
  const { data: county_stats_merged_data } = county_stats_merged;

  const { data: livestock_data, loading: loadingLivestockData } = ward_livestock_data_merged;

  let count_farmers_ward_query_ = county_stats_merged_data
    ? county_stats_merged_data.count_farmers_ward_query_
    : "";
  const [livestockSelected, SetlivestockSelected] = useState(null);
  const [wardSelected, SetWardSelected] = useState(null);

  const handleLivestockSelect = (value) => {
    SetlivestockSelected(value);
  };
  const handleWardSelect = (value) => {
    SetWardSelected(value);
    handleQueryLivestockData();
  };

  useEffect(() => {
    if (livestockSelected !== null) {
      dispatch(
        getWardLivestockData_Merged({
          county: user_county,
          ward: wardSelected,
          livestock_name: livestockSelected,
        })
      );
    }
  }, [livestockSelected]);

  const handleQueryLivestockData = () => {
    if (wardSelected) {
      dispatch(
        getWardLivestockData_Merged({
          county: user_county,
          ward: wardSelected,
          livestock_name: livestockSelected,
        })
      );
    }
  };
  return (
    <div>
      <PageLayout
        county_name={
          loginUserState.data !== null
            ? `${loginUserState.data.county_string} Livestock`
            : "-"
        }
      >
        <Cards isCropsPage={false} isLivestock={true} />

        <div class="flex flex-wrap mt-6 -mx-3">
          <div class="w-full max-w-full px-3 mt-0 lg:w-4/12 lg:flex-none">
            <div class="border-black/12.5 shadow-xl  relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
              <div class="p-4 pb-0 rounded-t-4"></div>
              <div class="flex-auto p-4 h-[500px] overflow-y-auto">
                <div className="mb-5">
                  <LivestockSelect handleSelect={handleLivestockSelect} />
                </div>

                {county_stats_merged_data && (
                  <>
                    <WardSelect
                      handleSelect={handleWardSelect}
                      data={count_farmers_ward_query_}
                    ></WardSelect>
                  </>
                )}


              </div>
            </div>
          </div>

          <div class="w-full max-w-full  mt-0 lg:w-8/12 lg:flex-none">
            
            <LivestockDataTable
              loadingData={loadingLivestockData}
              dataSelected={{
                county: user_county,
                ward: wardSelected,
                livestock: livestockSelected,
              }}
              ward_data_merged_data={livestock_data}
            ></LivestockDataTable>
          </div>
        </div>
      </PageLayout>
    </div>
  );
}

export default LivestockFarmers;
