import {
  Block,
  BookOpen,
  CheckBoxFill,
  CircleCheck,
  Leaf,
  Pencil,
  Water,
} from "akar-icons";
import React from "react";
import LeftRightDiv from "./LeftRightDiv";
import { returnOneName } from "../../../constants/utils";

function CropAgriculture({ data, farmer_data }) {
  let crop_agriculture =
    data !== null
      ? data.hasOwnProperty("crop_data")
        ? data["crop_data"]
        : null
      : null;

  let dataPassed = farmer_data !== null ? farmer_data[0] : null;

  return (
    <div className="container-fluid">
      {/* dataPassed {JSON.stringify(dataPassed)} */}
      <div className="row">
        {crop_agriculture !== null && (
          <>
            <div className="col-sm-6 p-0">
              <LeftRightDiv>
                <p>
                  <BookOpen /> Total crop land size{" "}
                  <strong>
                    {dataPassed["group_Section_C-acreage-Acreage_Crops"]} acres
                  </strong>
                </p>
                <span className="muted">
                  Farmer:{" "}
                  {
                   returnOneName(dataPassed[
                      "group_Section_B-group_farmer_details-Farmer_Name"
                    ]
                  )}
                  , {dataPassed.county_n} county
                </span>
              </LeftRightDiv>
            </div>

            <div className="row p-0">
              {crop_agriculture.map((d) => {
                return (
                  <div className="col-sm-6">
                    <div class="card p-0  mb-3">
                      <div class="card-header m-0 bg-success text-white">
                        {d.crop_label}
                      </div>
                      <div class="card-body">
                        <div className="row">
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Acreage">
                              {d["group_Crop_details-crop_acreage"]} acres
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Crop Production">
                              {d["group_Crop_details-crop_production"]}
                            </LeftRightDiv>
                          </div>

                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Purpose for crop">
                              {d["group_Crop_details-crop_purpose"]}
                            </LeftRightDiv>
                          </div>
                          {/* group_Crop_details-crop_production */}
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Water source">
                              <Water color="blue" />
                              {Array.isArray(
                                d["group_Crop_details-crop_watersource"]
                              ) ? (
                                <>
                                  {d["group_Crop_details-crop_watersource"].map(
                                    (ws_data) => {
                                      return (
                                        <div className="badge bg-primary m-1">
                                          {ws_data.value}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Fertilizer">
                              <div className="row">
                                {Array.isArray(
                                  d["group_Crop_details-crop_fertilizers"]
                                ) && (
                                  <>
                                    {d[
                                      "group_Crop_details-crop_fertilizers"
                                    ].map((d_fert) => {
                                      return (
                                        <div className="col-sm-12">
                                          <p>
                                            {/* {d_fert.value} */}
                                            <CheckBoxFill color="green" />{" "}
                                            {d_fert.value}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv
                              column={true}
                              text="Use certified seeds"
                            >
                              {d["group_Crop_details-crop_certifiedseeds"] ===
                              1 ? (
                                <>
                                  <CircleCheck color="green" /> Yes
                                </>
                              ) : (
                                <span className="text-muted">
                                  <Block color="gray" /> No{" "}
                                </span>
                              )}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv
                              column={true}
                              text="Fertilizer source"
                            >
                              {Array.isArray(
                                d["group_Crop_details-crop_fertilizersource"]
                              ) ? (
                                <>
                                  {d[
                                    "group_Crop_details-crop_fertilizersource"
                                  ].map((d_fert) => {
                                    return (
                                      <p>
                                        {" "}
                                        <CheckBoxFill color="green" />{" "}
                                        {d_fert.value}
                                      </p>
                                    );
                                  })}
                                </>
                              ) : (
                                "---"
                              )}

                              {d[
                                "group_Crop_details-crop_fertilizers_other"
                              ] !== null &&
                                d["group_Crop_details-crop_fertilizers_other"]}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Crop Membership">
                              {d["group_Crop_details-crop_membership"] === 1 ? (
                                <>
                                  <CircleCheck color="green" /> Yes
                                </>
                              ) : (
                                <span className="text-muted">
                                  <Block color="gray" /> No{" "}
                                </span>
                              )}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Crop Challenges">
                              {Array.isArray(
                                d["group_Crop_details-crop_challenges"]
                              ) && (
                                <>
                                  {d["group_Crop_details-crop_challenges"].map(
                                    (d_fert) => {
                                      return (
                                        <p>
                                          {" "}
                                          <CheckBoxFill color="gray" />{" "}
                                          {d_fert.value}
                                        </p>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {d["group_Crop_details-crop_challenges_other"] !==
                                null && (
                                <>
                                  Others:{" "}
                                  {
                                    d[
                                      "group_Crop_details-crop_challenges_other"
                                    ]
                                  }
                                </>
                              )}
                            </LeftRightDiv>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CropAgriculture;
