import { CheckBoxFill } from "akar-icons";
import React from "react";

function Rabbit({ data }) {
  return (
    <div>
      <div class="card p-0  mb-3">
        <div class="card-header m-0 bg-success text-white">Rabbit</div>
        <div class="card-body">
          <div className="table-responsive">
            <table className="ttable table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th>Label</th>
                  <th>Age less 2mnths female</th>
                  <th>Age less 2mnths male</th>
                  <th>Age  2-6mnths female</th>
                  <th>Age  2-6mnths male</th>
                  <th>Age above above 6mnths male</th>
                  <th>Age above above 6mnths male</th>


                  <th>Numbers</th>
                  <th>Production</th>

                  <th>Feeds</th>
                  <th>Fertilizer</th>
                  <th>Certified seeds</th>
                  <th>Feed source</th>
                  <th>Water source</th>
                  <th>Water distance</th>
                </tr>
              </thead>

              <tbody>
                {data.map((d) => {
                  return (
                    <tr>
                      <td>
                        <strong>{d["rabbit_label"]}</strong>
                      </td>

                      <td>{d["rabbit_age_group-rabbit_less_2mnth_female"]}</td>
                      <td>{d["rabbit_age_group-rabbit_less_2mnth_male"]}</td>
                      <td>{d["rabbit_age_group-rabbit_2_6mnths_female"]}</td>
                      <td>{d["rabbit_age_group-rabbit_2_6mnths_male"]}</td>
                      <td>{d["rabbit_age_group-rabbit_above_6mnths_female"]}</td>
                      <td>{d["rabbit_age_group-rabbit_above_6mnths_male"]}</td>


                      <td>{d["group_rabbit_details-rabbit_numbers"]}</td>
                      <td>
                        {Array.isArray(
                          d["group_rabbit_details-rabbit_production"]
                        ) ? (
                          <>
                            {d["group_rabbit_details-rabbit_production"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>

                      <td>
                        {Array.isArray(d["group_rabbit_details-rabbit_feeds"]) ? (
                          <>
                            {d["group_rabbit_details-rabbit_feeds"].map((d_f) => {
                              return (
                                <p>
                                  <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                  {d_f.value}
                                </p>
                              );
                            })}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>

                      <td>
                        {d["group_rabbit_details-rabbit_fertilizer"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                      <td>
                        {d["group_rabbit_details-rabbit_certifiedseeds"] === 1
                          ? "Yes"
                          : "No"}
                      </td>
                      <td>{d["group_rabbit_details-rabbit_feedssource"]}</td>

                      <td>
                        {Array.isArray(
                          d["group_rabbit_details-rabbit_watersource"]
                        ) ? (
                          <>
                            {d["group_rabbit_details-rabbit_watersource"].map(
                              (d_f) => {
                                return (
                                  <p>
                                    <CheckBoxFill color="green"></CheckBoxFill>{" "}
                                    {d_f.value}
                                  </p>
                                );
                              }
                            )}
                          </>
                        ) : (
                          "---"
                        )}
                      </td>
                      <td>{d["group_rabbit_details-rabbit_watersourcedistance"]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Rabbit;
