import React from 'react'
import LeftRightDiv from './LeftRightDiv';
import { Block, BookOpen, CheckBoxFill, CircleCheck, Water } from 'akar-icons';

function TreeCrop({data,farmer_data}) {
    let tree_agriculture =
    data !== null
      ? data.hasOwnProperty("tree_data")
        ? data["tree_data"]
        : null
      : null;

  let dataPassed = farmer_data !== null ? farmer_data[0] : null;
  return (
    <div>
{tree_agriculture !== null && (
          <>
            <div className="col-sm-6 p-0">
              <LeftRightDiv>
              
                <span className="muted">
                  Farmer:{" "}
                  {
                    dataPassed[
                      "group_Section_B-group_farmer_details-Farmer_Name"
                    ]
                  }
                  , {dataPassed.county_n} county
                </span>
              </LeftRightDiv>
            </div>

            <div className="row p-0">
              {tree_agriculture.map((d) => {
                return (
                  <div className="col-sm-6">
                    <div class="card p-0  mb-3">
                      <div class="card-header m-0 bg-success text-white">
                        {d.tree_crop_label}
                      </div>
                      <div class="card-body">
                        <div className="row">
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Acreage">
                              {d["tree_Crop_details-tree_crop_acreage"]} acres
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Tree Production">
                              {d["tree_Crop_details-tree_crop_production"]}
                            </LeftRightDiv>
                          </div>

                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Purpose for trees">
                              {d["tree_Crop_details-tree_crop_purpose"]}
                            </LeftRightDiv>
                          </div>
                          {/* group_Crop_details-crop_production */}
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Water source">
                              <Water color="blue" />
                              {Array.isArray(
                                d["tree_Crop_details-tree_crop_watersource"]
                              ) ? (
                                <>
                                  {d["tree_Crop_details-tree_crop_watersource"].map(
                                    (ws_data) => {
                                      return (
                                        <div className="badge bg-primary m-1">
                                          {ws_data.value}
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Fertilizer">
                              <div className="row">
                                {Array.isArray(
                                  d["tree_Crop_details-tree_crop_fertilizers"]
                                ) && (
                                  <>
                                    {d[
                                      "tree_Crop_details-tree_crop_fertilizers"
                                    ].map((d_fert) => {
                                      return (
                                        <div className="col-sm-12">
                                          <p>
                                            {/* {d_fert.value} */}
                                            <CheckBoxFill color="green" />{" "}
                                            {d_fert.value}
                                          </p>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv
                              column={true}
                              text="Crop seedlings"
                            >
                              {d["tree_Crop_details-tree_crop_seedlings"]}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv
                              column={true}
                              text="Fertilizer source"
                            >
                              {Array.isArray(
                                d["tree_Crop_details-tree_crop_fertilizersource"]
                              ) ? (
                                <>
                                  {d[
                                    "tree_Crop_details-tree_crop_fertilizersource"
                                  ].map((d_fert) => {
                                    return (
                                      <p>
                                        {" "}
                                        <CheckBoxFill color="green" />{" "}
                                        {d_fert.value}
                                      </p>
                                    );
                                  })}
                                </>
                              ) : (
                                "---"
                              )}

                              {d[
                                "tree_Crop_details-tree_crop_fertilizers_other"
                              ] !== null &&
                                d["tree_Crop_details-tree_crop_fertilizers_other"]}
                            </LeftRightDiv>
                          </div>

                          <div className="col-sm-6">
                            <LeftRightDiv
                              column={true}
                              text="Tree crop chemical interventiona"
                            >
                              {Array.isArray(
                                d["tree_Crop_details-tree_crop_chemical_interventions"]
                              ) ? (
                                <>
                                  {d[
                                    "tree_Crop_details-tree_crop_chemical_interventions"
                                  ].map((d_fert) => {
                                    return (
                                      <p>
                                        {" "}
                                        <CheckBoxFill color="green" />{" "}
                                        {d_fert.value}
                                      </p>
                                    );
                                  })}
                                </>
                              ) : (
                                "---"
                              )}

                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Crop Membership">
                              {d["tree_Crop_details-tree_crop_membership"] === 1 ? (
                                <>
                                  <CircleCheck color="green" /> Yes
                                </>
                              ) : (
                                <span className="text-muted">
                                  <Block color="gray" /> No{" "}
                                </span>
                              )}
                            </LeftRightDiv>
                          </div>
                          <div className="col-sm-6">
                            <LeftRightDiv column={true} text="Tree Crop Challenges">
                              {Array.isArray(
                                d["tree_Crop_details-tree_crop_challenges"]
                              ) && (
                                <>
                                  {d["tree_Crop_details-tree_crop_challenges"].map(
                                    (d_fert) => {
                                      return (
                                        <p>
                                          {" "}
                                          <CheckBoxFill color="gray" />{" "}
                                          {d_fert.value}
                                        </p>
                                      );
                                    }
                                  )}
                                </>
                              )}

                              {d["group_Crop_details-crop_challenges_other"] !==
                                null && (
                                <>
                                  Others:{" "}
                                  {
                                    d[
                                      "group_Crop_details-crop_challenges_other"
                                    ]
                                  }
                                </>
                              )}
                            </LeftRightDiv>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
    </div>
  )
}

export default TreeCrop